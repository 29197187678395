import { Injectable } from '@angular/core';
import { iif, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfService } from './app-conf.service';

@Injectable({ providedIn: 'root' })
export class HelpdeskService {

  phone: string;
  email: string;
  loaded = false;

  constructor(
    private appConfService: AppConfService
  ) { }

  load() {
    return iif(() => this.loaded,
      of({ phone: this.phone, email: this.email }),
      this.appConfService.getList({ upperConf: 'helpdesk' }).pipe(
        tap(res => {
          this.loaded = true;
          this.phone = res.appConfList?.find(appConf => appConf.conf === 'helpdesk.phone')?.confValue;
          this.email = res.appConfList?.find(appConf => appConf.conf === 'helpdesk.email')?.confValue;
        }),
        map(() => ({ phone: this.phone, email: this.email }))
      )
    );
  }
}