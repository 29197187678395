import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';

@Injectable()
export class DeptFavService {
  deptFavoritePath: string = '/v1/api/idp/dept/favorite';
  deptPath: string = '/v1/api/idp/org/dept/mgnt';

  constructor(private apiService: ApiService) {}

  getDeptTreeList(params?: any) {
    return this.apiService.post(this.deptPath.concat('/getTree'), params);
  }

  getDeptUnitList(params?: any) {
    return this.apiService.post(this.deptPath.concat('/getDeptUnitList'), params);
  }

  multiInsert(params?: any) {
    return this.apiService.post(
      this.deptFavoritePath.concat('/multiInsert'),
      params
    );
  }

}
