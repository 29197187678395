<div class="modal-default modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header" modalDraggable>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">
        <span></span>
      </button>
      <h1 class="modal-title">{{'title_pw_init' | translate}}</h1>
    </div>
    <div class="modal-body">
      <form [formGroup]="inputForm" novalidate autocomplete="off">
        <table class="table-detail">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th><span>{{'lbl_auth_way' | translate}}</span></th>
              <td>
                <div class="input-radio">
                  <label *ngIf="smsEnabled"><input type="radio" value="SY007SMS" class="form-control" formControlName="authWayCd"><span>{{'SY007SMS' | translate}}</span></label>
                  <label *ngIf="emailEnabled"><input type="radio" value="SY007MAI" class="form-control" formControlName="authWayCd"><span>{{'SY007MAI' | translate}}</span></label>
                </div>
              </td>
            </tr>
            <tr>
              <th><span>{{'lbl_nm' | translate}}</span></th>
              <td>
                <input type="text" class="form-control" formControlName="userNm">
                <ng-container *ngIf="inputForm.controls.userNm.invalid && (inputForm.controls.userNm.dirty || inputForm.controls.userNm.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.userNm.errors.required || inputForm.controls.userNm.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_nm' | translate} }}
                  </p>
                </ng-container>
                
              </td>
            </tr>
            <tr>
              <th><span>{{'lbl_login_id' | translate}}</span></th>
              <td>
                <input type="text" class="form-control" formControlName="loginId">
                <ng-container *ngIf="inputForm.controls.loginId.invalid && (inputForm.controls.loginId.dirty || inputForm.controls.loginId.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.loginId.errors.required || inputForm.controls.loginId.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_login_id' | translate} }}
                  </p>
                </ng-container>
                
              </td>
            </tr>
            <tr *ngIf="inputForm.controls.authWayCd.value === 'SY007SMS'">
              <th>
                <span>{{'lbl_mob_no' | translate}}</span>
              </th>
              <td>
                <input type="text" class="form-control" formControlName="userMobno">
                <button type="button" class="btn btn-default btn-inline" (click)="sendAuthKey()"
                  [disabled]="!inputForm.controls.userNm.value || !inputForm.controls.loginId.value || !inputForm.controls.userMobno.value">
                  {{'lbl_auth_req' | translate}}
                </button>
                <ng-container *ngIf="inputForm.controls.userMobno.invalid && (inputForm.controls.userMobno.dirty || inputForm.controls.userMobno.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.userMobno.errors.required || inputForm.controls.userMobno.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_mob_no' | translate} }}
                  </p>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="inputForm.controls.authWayCd.value === 'SY007MAI'">
              <th>
                <span>{{'lbl_email' | translate}}</span>
              </th>
              <td>
                <input type="text" class="form-control" formControlName="userEmail">
                <button type="button" class="btn btn-default btn-inline" (click)="sendAuthKey()"
                  [disabled]="!inputForm.controls.userNm.value || !inputForm.controls.loginId.value || !inputForm.controls.userEmail.value || !inputForm.controls.userEmail.valid">
                  {{'lbl_auth_req' | translate}}
                </button>
                <ng-container *ngIf="inputForm.controls.userEmail.invalid && (inputForm.controls.userEmail.dirty || inputForm.controls.userEmail.touched)">
                  <p class="invalid-text" *ngIf="inputForm.controls.userEmail.errors.required || inputForm.controls.userEmail.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_email' | translate} }}
                  </p>
                  <p class="invalid-text" *ngIf="inputForm.controls.userEmail.errors.email">
                    {{'msg_warn_00093' | translate : {param1:'lbl_email' | translate} }}
                  </p>

                </ng-container>
              </td>
            </tr>
            <tr>
              <th><span>{{'lbl_auth_key' | translate}}</span></th>
              <td>
                <input type="text" class="form-control" formControlName="authKey">
                <ng-container *ngIf="inputForm.controls.authKey.invalid && (inputForm.controls.authKey.dirty || inputForm.controls.authKey.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.authKey.errors.required || inputForm.controls.authKey.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_auth_key' | translate} }}
                  </p>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-block page-btn">
          <ul class="list-inline float-right pull-right">
            <li>
              <button class="btn btn-primary" (click)="confirm()" [disabled]="!inputForm.valid">{{'btn_conf' | translate}}</button>
            </li>
            <li>
              <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{'btn_close' | translate}}</button>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</div>