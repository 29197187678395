import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@app/common/core/models';
import { MenuService, SessionService } from '@app/common/core/services';
import { LnggService } from '@app/contents/sys/lngg/lngg.service';
import { SysAuthService } from '@app/contents/sys/sys-auth/sys-auth.service';
import { TimezoneService } from '@app/contents/sys/timezone/timezone.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UpdRsnPopupComponent } from '../upd-rsn-popup/upd-rsn-popup.component';
import { MyInfoService } from './my-info.service';

@Component({
  selector: 'app-my-info-popup',
  templateUrl: './my-info-popup.component.html',
  styleUrls: []
})
export class MyInfoPopupComponent implements OnInit {

  @Output()
  close = new EventEmitter<any>();

  inputForm = this.formBuilder.group({
    lnggCd: [],
    timeZoneId: [],
    sysAuthCd: []
  });

  lnggList: any[] = [];
  timeZoneList: any[] = [];
  sysAuthUnitList: any[] = [];
  sysAuthList: any[] = [];

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private lnggService: LnggService,
    private timezoneService: TimezoneService,
    private myInfoService: MyInfoService,
    private sysAuthService: SysAuthService,
    private modalService: BsModalService,
    private menuService: MenuService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getLnggList();
    this.getTimeZoneList();
    this.getSysAuthUnitList();
  }

  getLnggList() {
    this.lnggService.getLnggUnitList().subscribe(res => {
      this.lnggList = res.lnggUnitList || [];
      this.inputForm.controls.lnggCd.setValue(
        this.lnggList.find(lngg => lngg.lnggCd === this.sessionService.getUser().lnggCd)?.lnggCd
        || this.lnggList[0].lnggCd
      );
    });
  }

  getTimeZoneList() {
    this.timezoneService.getTimeZoneUnitList().subscribe(res => {
      this.timeZoneList = res.timeZoneList || [];
      this.inputForm.controls.timeZoneId.setValue(
        this.timeZoneList.find(timeZone => timeZone.timeZoneId === this.sessionService.getUser().timeZoneId)?.timeZoneId
        || this.timeZoneList[0].timeZoneId
      );
    });
  }

  getSysAuthUnitList() {
    this.sysAuthService.getSysAuthUnitList().subscribe(res => {
      this.sysAuthUnitList = res.sysAuthUnitList || [];
      this.getSysAuthList();
    }); 
  }

  getSysAuthList() {
    this.sysAuthService.getList({loginId:  this.sessionService.getUser().loginId}).subscribe(res => {
      this.sysAuthList = res.sysAuthList || [];
      this.sysAuthUnitList.forEach(item => {
        item.removeDisable = this.sysAuthList.findIndex(sysAuth => 
          item.sysAuthCd === sysAuth.sysAuthCd && sysAuth.mainSysAuthYn === 'Y') > -1 ? true : false;
      });
      this.inputForm.controls.sysAuthCd.setValue(this.sessionService.getUser().sysAuthCd);
    });
  }

  confirm() {
    const dto = {
      lnggCd: this.inputForm.controls.lnggCd.value,
      timeZoneId: this.inputForm.controls.timeZoneId.value
    };
    
    if (this.inputForm.controls.sysAuthCd.value !== this.sessionService.getUser().sysAuthCd) {
      this.modalService.show(UpdRsnPopupComponent, {
        ignoreBackdropClick: true,
        keyboard: false
      }).content.updRsn.subscribe(data => {
        this.updateMyInfo({...dto, sysAuthCd: this.inputForm.controls.sysAuthCd.value, updRsn: data});
      });      
    } else {
      this.updateMyInfo(dto);
    }
  }

  updateMyInfo(dto) {
    this.myInfoService.updateMyInfo(dto).subscribe(res => {
      if (res.rsltCd === '00') {
        //  세션에 수정한 내용 저장하고 대시보드로 이동, 메뉴 다시 불러오기
        const currentUser = this.sessionService.getUser();

        const user: User = {
          ...this.sessionService.getUser(),
          lnggCd: dto.lnggCd,
          timeZoneId: dto.timeZoneId,
          sysAuthCd: dto.sysAuthCd ? dto.sysAuthCd : this.sessionService.getUser().sysAuthCd,
          sysAuthRank: dto.sysAuthCd ? 
          this.sysAuthList.find(item => item.sysAuthCd === dto.sysAuthCd).sysAuthRank : this.sessionService.getUser().sysAuthRank
        };
        this.sessionService.storeSession(user); 
        
        // 시스템권한이 변경된 경우 메뉴를 조회 (언어 변경시 메뉴를 조회하기 때문에 중복을 피함)
        if (currentUser.lnggCd === user.lnggCd && currentUser.sysAuthCd !== user.sysAuthCd) {
          this.menuService.load();
        }

        this.router.navigateByUrl('/');
      }
    });

    this.modalRef.hide();
  }
}
