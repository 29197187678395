import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

/**
 * ------------------------------------------------
 * Grid Select renderer
 *
 * Author : LIM M,S
 *
 * History
 * - 2018.02.05 initial draft (LIM, M.S)
 *
 * ------------------------------------------------
 */
@Component({
    selector: 'select-editor-cell',
    template: `
        <div class="form-group">
            <select [(ngModel)]="selectedData" (change)="onChange()" class="input-select">
                <option *ngFor="let option of options" [value]="option.id">{{option.text}}</option>
            </select>
        </div>
    `
})
export class GridSelectComponent implements ICellEditorAngularComp {
    private params: any;
    selectedData: any;
    options: any[];
    width: string;

    /**
     * grid로 부터 value, parameter를 받아옴
     * @param {any} params cell value와 cellEditorParams에 정의된 value를 받아옴
     */
    agInit(params: any): void {
        this.params = params;
        if (params.value !== undefined) {
            this.selectedData = params.value.id;
        }
        this.options = params.options;
        this.width = params.width;
    }

    /**
     * grid로 value return
     */
    getValue(): any {
        let selectedItem = {};
        selectedItem = this.options.find(item => {
            return item.id === this.selectedData;
        });

        return selectedItem;
    }

    /**
     * editor를 popup으로 표시할지 여부를 정함 true: floating으로 표시
     */
    isPopup(): boolean {
        return true; // grid 위에 floating으로 표시
    }

    /**
     * select box 데이타 변경 시 그리드의 edit모드를 종료하여 데이터를 반영시킴
     */
    onChange() {
        this.params.api.stopEditing();
    }
}
