import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './app-sidebar-header.component.html'
})
export class AppSidebarHeaderComponent implements OnInit {

  /**
   * 네비게이션 정보
   */
  @Input() navigation: any;

  /**
   * 현재 URL 패스
   */
  path: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const url: UrlSegment[] =  this.route.snapshot.url;
    this.path = url && url.length ? url[0].path : '';
  }
}
