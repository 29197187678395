import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@common/shared/shared.module';
/**
 * Import app components
 */
import { AppAsideComponent, AppBreadcrumbsComponent, AppFooterComponent, AppHeaderComponent, AppSidebarComponent, AppSidebarFooterComponent, AppSidebarFormComponent, AppSidebarHeaderComponent, AppSidebarMinimizerComponent, APP_SIDEBAR_NAV } from './components';
/**
 * Import containers
 */
import { ContentLayoutComponent, FullLayoutComponent, SimpleLayoutComponent } from './containers';
/**
 * Import directives
 */
import { AsideToggleDirective, HEADER_DIRECTIVES, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES } from './directives';


const APP_CONTAINERS = [
  FullLayoutComponent,
  ContentLayoutComponent,
  SimpleLayoutComponent
];


const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
];


const APP_DIRECTIVES = [
  ReplaceDirective,
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  HEADER_DIRECTIVES,
  SIDEBAR_TOGGLE_DIRECTIVES
];


/**
 * 프레임셋 모듈
 * @description 어플리케이션 레이아웃 및 프레임셋을 구성하는 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ...APP_COMPONENTS,
    ...APP_CONTAINERS,
    ...APP_DIRECTIVES
  ]
})
export class FramesetModule { }
