<aside class="aside-menu">
  <div class="alert-header">{{'title_notification_list' | translate}}</div>
  <div class="alert-body">
    <ul>
      <li *ngFor="let notification of notificationService.notificationList">
        <i [ngClass]="{'excl-circ': notification.type === 'alert', 'info': notification.type === 'info', 'excl-tri': notification.type === 'warn'}" aria-hidden="true"></i>
        <p>{{notification.title}}</p>
        <p class="text-muted">{{notification.recvDtm | timezonedDate: 'YYYY-MM-DD HH:mm:ss'}}</p>
      </li>
    </ul>
  </div>
  <div class="aside-footer">
    <ul class="buttons">
      <li (click)="clear()">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </li>
      <li (click)="setupOpen = !setupOpen">
        <i class="fa fa-cog" aria-hidden="true"></i>
      </li>
    </ul>
    <ul class="setup" [class.open]="setupOpen">
      <li>
        <span>{{'lbl_notice' | translate}}</span>
        <button type="button" class="btn-use ng-star-inserted" [class.active]="setup.notice" (click)="toggle('notice')"><i aria-hidden="true" class="fa"></i></button>
      </li>
      <li>
        <span>{{'lbl_alert' | translate}}</span>
        <button type="button" class="btn-use ng-star-inserted" [class.active]="setup.alert" (click)="toggle('alert')"><i aria-hidden="true" class="fa"></i></button>
      </li>
    </ul>
  </div>
</aside>
