import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { APP_DIRECTIVES } from './directive';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ImgPreloadDirectiveDirective } from './img/img-preload-directive.directive';
import { AgGridCustomModule } from './libs/ag-grid/ag-grid-custom.module';
import { APP_PIPES } from './pipe';
import { ToolbarComponent } from './tooblar/toolbar.component';
import { TOOLBAR_DIRECTIVES } from './tooblar/toolbar.directive';
import { CustomValidatorModule } from './validators/custom-validator.module';

/**
 * 공통 모듈
 * @description 기능 모듈에서 사용하는 공통기능을 모아놓은 공유 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    CommonModule,
    FileUploadModule
  ],
  declarations: [
    // components
    ToolbarComponent,
    FileuploadComponent,

    // directives
    ...TOOLBAR_DIRECTIVES,
    ImgPreloadDirectiveDirective,
    ...APP_DIRECTIVES,

    // pipes
    ...APP_PIPES
  ],
  providers: [],
  exports: [
    // modules
    AgGridCustomModule,
    FileUploadModule,
    CustomValidatorModule,

    // components
    FileuploadComponent,
    ToolbarComponent,

    // directives
    ...TOOLBAR_DIRECTIVES,
    ImgPreloadDirectiveDirective,
    ...APP_DIRECTIVES,

    // pipes
    ...APP_PIPES,
  ]
})
export class SharedCommonModule {}
