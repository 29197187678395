import { Injectable } from '@angular/core';
import { ApiService } from '../../http';

@Injectable({ providedIn: 'root' })
export class AuthKeyService {

  path = '/v1/api/idp/sys/authkey';

  constructor(
    private apiService: ApiService
  ) { }

  send(params) {
    return this.apiService.post(this.path.concat('/send'), params);
  }
}