import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';

@Injectable({ providedIn: 'root' })
export class LoginPwService {

  constructor(
    private apiService: ApiService
  ) { }

  update(params) {
    return this.apiService.post('/v1/api/idp/sys/login/pw/update', params);
  }

  reset(params) {
    return this.apiService.post('/v1/api/idp/sys/login/pw/reset', params);
  }
}