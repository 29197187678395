import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { passwordUseWord } from './password-use-word.validator';

const PWD_USE_WORD_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PasswordUseWordValidator),
  multi: true
};

@Directive({
  selector: '[passwordUseWord][formControlName],[passwordUseWord][formControl],[passwordUseWord][ngModel]',
  providers: [PWD_USE_WORD_VALIDATOR]
})
export class PasswordUseWordValidator implements Validator, OnInit, OnChanges {
  @Input() words: string[];

  private validator: ValidatorFn;
  private onChange: () => void;

  ngOnInit() {
    this.validator = passwordUseWord(this.words);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let key in changes) {
      if (key === 'passwordUseWord') {
        this.validator = passwordUseWord(changes[key].currentValue);
        if (this.onChange) this.onChange();
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
