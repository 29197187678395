import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <section class="error-page">
      <div class="p-5">
        <div class="error-box">
          <img src="assets/images/icon/exclamation_triangle_red.png" alt="error icon">
          <h2>오류가 발생하였습니다.</h2>
          <p>요청하신 페이지가 존재하지 않거나 접근할 수 없습니다.</p>
          <p>
            <a routerLink="/login">로그인 화면으로 돌아가기</a>
          </p>
          <p class="d-none"><b>담당자: </b>LUA 관리자</p>
        </div>
      </div>
    </section>
  `,
  styles: [`
    .error-page {
      width: 100%;
      height: 100vh;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .error-page > div {
      width: 600px;
      height: 350px;
    }
    .error-box {
      width: 100%;
      height: 100%;
      border-top: 2px solid #4d5762;
      background-color: #fff;
      text-align: center;
    }
    .error-box img {
      margin-top: 55px;
    }
    .error-box h2 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-family: "LGSmHaSB", sans-serif;
      font-size: 2.0rem;
      color: #454545;
    }
    .error-box p {
      line-height: 1.8rem;
      font-size: 1.2rem;
      color: #202020;
    }
    .error-box p + p {
      margin-top: 18px;
    }
  `]
})
export class NotFoundComponent {}
