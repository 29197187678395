import { Directive, forwardRef, Input } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { passwordMatch } from './password-match.validator';


const PWD_MATCH_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PasswordMatchValidator),
  multi: true
};

@Directive({
  selector: '[passwordMatch][ngModelGroup],[passwordMatch][formGroupName]',
  providers: [PWD_MATCH_VALIDATOR]
})
export class PasswordMatchValidator implements Validator {
  @Input() passwordControlName: string;
  @Input() passwordConfirmControlName: string;

  validate(group: FormGroup): ValidationErrors | null {
    return passwordMatch(this.passwordControlName, this.passwordConfirmControlName);
  }
}
