import { environment } from '@environments/environment';
import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import * as SockJS from 'sockjs-client';

/**
 * 웹소켓 엔드포인트
 * @type {string}
 */
export const websocketEndpoint = environment.websocket.endpoint;

/**
 * 웹소켓 프로바이더 (SockJS)
 */
export const socketProvider = () => {
  return new SockJS(websocketEndpoint);
};

/**
 * 웹소켓 기본 설정
 * @type {InjectableRxStompConfig}
 */
export const stompConfig: InjectableRxStompConfig = {
  // Which server?
  webSocketFactory: socketProvider,

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    // login: 'guest',
    // passcode: 'guest'
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  // Typical value 0 - disabled
  heartbeatIncoming: 0,

  // Typical value 20000 - every 20 seconds
  heartbeatOutgoing: 20000,

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnectDelay: 5000,

  // Will log diagnostics on console
  debug: (msg) => { if (!environment.production) console.log(new Date(), msg); }
};
