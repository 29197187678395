import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-select-editor-cell',
  template: `
                <ul [style.width.px]="width">
                    <li>
                        <select [(ngModel)]="itemTypeCd" class="input-select"  (change)="changeItemTypeDtlsList()">
                            <option *ngFor="let type of itemTypeList" [value]="type.comnCd">{{type.comnCdNm}}</option>
                        </select>                     
                        <select [(ngModel)]="itemTypeDtlsCd" class="input-select"
                            *ngIf="itemTypeCd === 'ID005TYP0TUR' || itemTypeCd === 'ID005TYP0TVT'">
                            <option *ngFor="let type of itemTypeDtlsList" [value]="type.comnCd">{{type.comnCdNm}}</option>
                        </select>
                    </li>
                    <li>
                        <input type="text" [(ngModel)]="printText" *ngIf="itemTypeCd === 'ID005TYP0TTX'" [maxLength]="100">
                        <span *ngIf="itemTypeCd === 'ID005TYP0IFL'"><U>{{imgFileNm}}</U> </span>
                    </li>
                    <li>
                        <div class="custom-file excel" *ngIf="itemTypeCd === 'ID005TYP0IFL'">
                            <label class="custom-file-label btn-default" for="customFileLang">
                                <span>찾아보기</span>
                                <input type="file" class="custom-file-input btn btn-module" accept="image/jpeg" (change)="onFileChange($event)">
                            </label>
                        </div>                        
                    </li> 
                </ul>
             `,
  styles: [
    `
      ul {
        height: 31px;
        display: flex;
        align-items: center;
      }
      ul li input {
        width: 100%;
        height: 100%;
        border: none;
      }
      select {
        border: transparent;
      }
      div.custom-file {
        height: 25px;
      }
      .custom-file.excel .custom-file-label {
        height: 25px;
      }
    `
  ]
})
export class GridMultiSelectInputComponent implements ICellEditorAngularComp {
  params: any;
  width: number;
  target: DataTransfer;
  itemTypeCd: any;
  itemTypeList: any[];
  itemTypeDtlsCd: any;
  itemTypeDtlsList: any[];
  userItemTypeDtlsList: any[];
  textItemTypeDtlsList: any[];
  printText: any;
  imgFileNm: any;
  file: any;

  /**
   * grid로 부터 value, parameter를 받아옴
   * @param {any} params cell value와 cellEditorParams에 정의된 value를 받아옴
   */
  agInit(params: any) {
    this.params = params;
    this.width = params.column.getActualWidth() - 1;
    this.itemTypeCd = params.node.data.itemTypeCd;
    this.itemTypeList = params.itemTypeList;
    this.itemTypeDtlsCd = params.node.data.itemTypeDtlsCd;
    this.userItemTypeDtlsList = params.userItemTypeDtlsList;
    this.textItemTypeDtlsList = params.textItemTypeDtlsList;
    this.printText = params.node.data.printText;
    this.imgFileNm = params.node.data.imgFileNm;
    this.setItemTypeDtlsList();
  }

  /**
   * grid로 value return
   * @return {any}
   */
  getValue(): any {
    const value = {};
    value['itemTypeCd'] = this.itemTypeCd;
    value['itemTypeDtlsCd'] = this.itemTypeDtlsCd;
    value['itemTypeNm'] = this.itemTypeCd ? this.itemTypeList.find(type => type.comnCd === this.itemTypeCd).comnCdNm : null;
    value['itemTypeDtlsNm'] = this.itemTypeDtlsCd ? this.itemTypeDtlsList.find(type => type.comnCd === this.itemTypeDtlsCd)?.comnCdNm : null;
    value['printText'] = this.printText;
    value['imgFileNm'] = this.imgFileNm;
    value['file'] = this.file;
    return value;
  }

  /**
   * editor를 popup으로 표시할지 여부를 정함 true: floating으로 표시
   * @return {boolean}
   */
  isPopup(): boolean {
    return true; // grid 위에 floating으로 표시
  }

  /**
  * select box 데이타 변경 시 그리드의 edit모드를 종료하여 데이터를 반영시킴
  */
  onChange() {
    this.params.api.stopEditing();
  }

  setItemTypeDtlsList() {
    if (this.itemTypeCd === 'ID005TYP0TUR') {
      this.itemTypeDtlsList = this.userItemTypeDtlsList;
      this.itemTypeDtlsCd = this.itemTypeDtlsList[0].comnCd;
    } else if (this.itemTypeCd === 'ID005TYP0TVT') {
      this.itemTypeDtlsList = this.textItemTypeDtlsList;
      this.itemTypeDtlsCd = this.itemTypeDtlsList[0].comnCd;
    }
    else {
      this.itemTypeDtlsList = [];
      this.itemTypeDtlsCd = null;
    }
  }

  changeItemTypeDtlsList() {
    this.setItemTypeDtlsList();
  }

  public onFileChange(event) {
    this.target = <DataTransfer>event.target;
    this.file = this.target.files[0];
    this.imgFileNm = this.target.files[0].name;
  }
}
