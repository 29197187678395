import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@common/core/http';

@Injectable()
export class LoginUserFavService {

  private loginIdPath = '/v1/api/idp/sys/login/id';

  constructor(
    private apiService: ApiService,
  ) {}

  /**
   * 사업기회 목록 조회
   * @param params
   * @returns {Observable<any>}
   */
  getLoginIdUserList(params?: any): Observable<any> {
    return this.apiService.post(this.loginIdPath.concat('/getLoginIdUserList'), params);
  }

}
