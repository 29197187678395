import { Injectable } from '@angular/core';

/**
 * 네이티브 브라우저의 윈도우 오브젝트를 반환
 * @returns {any}
 * @private
 */
function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}
