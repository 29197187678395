<div class="modal-default modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header" modalDraggable>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">
        <span>close</span>
      </button>
      <h1 class="modal-title panel-heading">{{'title_upd_rsn_setting' | translate}}</h1>
    </div>    
    <div class="modal-body">  
      <form [formGroup]="formGroup" autocomplete="off">
        <section class="module-wrapper">
          <table class="table-detail">
            <caption></caption>
            <colgroup>
              <col style="width:30%">
              <col style="width:70%">
            </colgroup>
            <tbody>
              <tr>
                <th class="required"><span>{{'lbl_upd_rsn' | translate}}</span></th>
                <td>
                  <input type="text" class="form-control wd-full" formControlName="updRsn">
                  <div *ngIf="formGroup.controls['updRsn']?.invalid && (formGroup.controls['updRsn']?.dirty || formGroup.controls['updRsn']?.touched)">
                    <span class="validate-info" *ngIf="formGroup.controls['updRsn']?.errors.required || formGroup.controls['updRsn']?.errors.whitespace">
                      <p>{{'msg_warn_00026' | translate : {param1:'lbl_upd_rsn' | translate} }}</p>
                    </span>
                    <span class="validate-info" *ngIf="formGroup.controls['updRsn']?.errors.maxlength">
                      <p>{{'msg_warn_00036' | translate : {param1:'lbl_upd_rsn' | translate, param2: 100} }}</p>
                    </span>
                  </div>
                </td>                                    
              </tr>        
            </tbody>
          </table>
        </section>
        <div class="btn-block page-btn">
          <ul class="list-inline float-right">                
            <li>
              <button type="button" class="btn btn-primary" type="button" [disabled]="formGroup.invalid" (click)="save()">{{'btn_save' | translate}}</button>
            </li>
            <li>
              <button type="button" class="btn btn-default" type="button" (click)="modalRef.hide()">{{'btn_close' | translate}}</button>
            </li>   
          </ul>
        </div>
      </form>
    </div>  
  </div>  
</div>
  