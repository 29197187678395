import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AES, enc } from 'crypto-js';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentMenu } from '../models/global/current-menu.model';
import { AuthService, DataService, LoginService, NotifyService, SessionService } from '../services';


/**
 * 인증 및 권한 체크 가드
 * @description 인증 및 메뉴 권한체크를 처리하는 가드
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class AuthGuard implements CanActivate {

  _currentMenu: CurrentMenu;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private notifyService: NotifyService,
    private dataService: DataService,
    private translateService: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.checkAuthentication(state.url.split('?')[0]);
  }

  /**
   * 인증 및 메뉴 사용권한 체크
   * @param {string} url
   * @returns {boolean | Observable<boolean>}
   */
  checkAuthentication(url: string): boolean | Observable<boolean> {
    const _previousMenuId = this.dataService.getCurrentMenu() ? this.dataService.getCurrentMenu().menuId : '';
    // this.dataService.clearCurrentMenu();

    return this.sessionService.isAuthenticated$
      .pipe(
        take(1),
        tap((authenticated: boolean) => {
          if (!authenticated) {
            this.loginService.logout()
              .pipe(
                finalize(() => {
                  if ('/home' !== url && '/' !== url) {
                    this.notifyService.error(this.translateService.instant('msg_warn_00119'), this.translateService.instant('lbl_login_expired'));
                  }

                  this.router.navigateByUrl('/login');
                })
              )
              .subscribe();
          }
        }),
        switchMap((authenticated: boolean) => {
          if (!authenticated) {
            return of(false);
          } else {
            const currentDtm = moment();
            return this.authService.hasMenuAuthority(_previousMenuId, url, currentDtm)
              .pipe(
                take(1),
                tap((data: any) => {
                  if (data) {
                    this.dataService.setCurrentMenu({ menuId: data.menuId, menuProgId: data.menuProgId, progAuth: data.progAuth });
                    if (_previousMenuId !== data.menuId) {
                      this.dataService.clearData();
                    }

                    if (!data.result) {
                      this.notifyService.error(this.translateService.instant('msg_warn_00120'), this.translateService.instant('lbl_auth_fail'));
                      this.router.navigateByUrl('/');
                    }
                  }
                }),
                map((data: any) => {
                  const key = currentDtm.utc().format('YYMMDDTHHmmssSSS');
                  const iv = _previousMenuId.padEnd(16, '0');
                  return AES.decrypt(data.result, enc.Utf8.parse(key), {iv: enc.Utf8.parse(iv)}).toString(enc.Utf8) === 'true';
                }),
                catchError((err: any) => {
                  if (!err.status || err.status === 401 || err.status === 404) {
                    this.loginService.logout()
                      .pipe(
                        finalize(() => {
                          if ('/home' !== url && '/' !== url) {
                            this.notifyService.error(this.translateService.instant('msg_warn_00119'), this.translateService.instant('lbl_login_expired'));
                          }
                          this.router.navigateByUrl('/login');
                        })
                      )
                      .subscribe();

                  } else {
                    this.notifyService.error(this.translateService.instant('msg_warn_00120'), this.translateService.instant('lbl_auth_fail'));
                    this.router.navigateByUrl('/access-denied');
                  }

                  return of(false);
                })
              );
          }
        })
      );
  }
}
