import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GridLoadingRendererComponent } from './grid-loading-renderer.component';
import { GridValidationTooltipComponent } from './grid-validation-tooltip.component';
import { AgGridHeaderComponent, AgGridNgSelectComponent, AgGridPaginationComponent, GridButtonRendererComponent, GridDatepickerComponent, GridDatepickerRangeComponent, GridLabelIconRendererComponent, GridMultiSelectComponent, GridMultiSelectInputComponent, GridPopupRendererComponent, GridSelectAllComponent, GridSelectComponent, GridSelectEditorComponent, GridSwitchButtonRendererComponent, GridTextIconRendererComponent, GridTimepickerComponent } from './index';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule,
    DpDatePickerModule
  ],
  declarations: [
    AgGridHeaderComponent,
    AgGridPaginationComponent,
    AgGridNgSelectComponent,
    GridButtonRendererComponent,
    GridDatepickerComponent,
    GridDatepickerRangeComponent,
    GridMultiSelectComponent,
    GridPopupRendererComponent,
    GridSelectComponent,
    GridSelectAllComponent,
    GridMultiSelectInputComponent,
    GridLabelIconRendererComponent,
    GridTimepickerComponent,
    GridSelectEditorComponent,
    GridSwitchButtonRendererComponent,
    GridTextIconRendererComponent,
    GridValidationTooltipComponent,
    GridLoadingRendererComponent
  ],
  providers: [],
  exports: [
    AgGridHeaderComponent,
    AgGridPaginationComponent
  ]
})
export class AgGridCustomModule { }

