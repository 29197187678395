import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-switch-button',
  template: `
    <button type="button" class="btn-use" [class.active]="params.value === 'Y'" (click)="click()"><i class="fa" aria-hidden="true"></i></button>
  `
})
export class GridSwitchButtonRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  func: Function;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.func = params.colDef.cellRendererParams.func;
  }

  click() {
    this.func(this.params);
  }

  refresh(): boolean {
    return false;
  }
}
