import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from '@common/core/services';
import { Subscription } from 'rxjs';

/**
 * 어플리케이션 루트 컴포넌트
 * @description
 * @author
 * @license IDP v1.0.0
 */
@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * 라우터 이벤트 구독
   */
  routerSubscription: Subscription;

  constructor(
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    // Application signature
    try {
      const logoStyle = 'color:#f6782e;font-weight:bold;font-size:12px';
      const messageStyle = 'font-size:12px;font-weight:bold;color:#20e4e4;';
      const logoText = `

      ██╗██████╗ ██████╗     ██████╗ ██╗     ██╗   ██╗███████╗
      ██║██╔══██╗██╔══██╗    ██╔══██╗██║     ██║   ██║██╔════╝
      ██║██║  ██║██████╔╝    ██████╔╝██║     ██║   ██║███████╗
      ██║██║  ██║██╔═══╝     ██╔═══╝ ██║     ██║   ██║╚════██║
      ██║██████╔╝██║         ██║     ███████╗╚██████╔╝███████║
      ╚═╝╚═════╝ ╚═╝         ╚═╝     ╚══════╝ ╚═════╝ ╚══════╝

      `;

      const messageText = '\nIDP Plus v1.0\n\n';
      console.log('%c%s%c%s', logoStyle, logoText, messageStyle, messageText);
      console.log('%cisDevMode(): %s', messageStyle, isDevMode());
    } catch (e) { }

    // 라우팅 변경 시 스크롤바 위치 초기화
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * 세션 사용자명
   * @returns {boolean}
   */
  get userNm(): string {
    return this.sessionService.getUserNm();
  }
}

