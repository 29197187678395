import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * 공통코드 관리 서비스
 *
 * 어플리케이션 공통코드를 조회하는 서비스
 * 어플리케이션 구현시 응용요구사항에 부합하도록 재구현하시기 바랍니다.
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class CommonCodeService {

  private resourceUrl = `/v1/api/common`;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 공통코드 조회
   * @param {string} codeGroupCd
   * @param params
   * @returns {Observable<any[]>}
   */
  getCommonCode(codeGroupCd: string, params?: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.resourceUrl}/common-code/${codeGroupCd}`, { params });
  }

  /**
   * 공통코드 조회 (multiple query)
   * @param {Array<string>} codeGroupCds
   * @param params
   * @returns {Observable<any>}
   */
  getMultipleCommonCode(codeGroupCds: Array<string>, params?: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/common-codes/${codeGroupCds.join(',')}`, { params });
  }

  // Mock API
  // 로컬 개발모드를 위한 Mock API 서비스

  /**
   * 공통코드 조회 (MockService)
   * @param {string} codeGroupCd
   * @param params
   * @returns {Observable<any[]>}
   */
  getCommonCodeByJsonData(codeGroupCd: string, params?: any): Observable<any[]> {
    return this.http.get<any>(`${environment.jsonDataUrl}/common/common-code.json`, { params })
      .pipe(
        map((data) => {
          return data[codeGroupCd];
        })
      );
  }
}
