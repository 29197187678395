import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@app/common/core/services';

import * as moment from 'moment-timezone';

@Pipe({ name: 'timezonedDate' })
export class TimezonedDatePipe implements PipeTransform {

  constructor(private sessionService: SessionService) {
  }

  transform(date: Date, format?: string): string {
    return date ? moment.tz(date, this.sessionService.getUser().timeZoneId).format(format || 'YYYY-MM-DD') : '';
  }
}