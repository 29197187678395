import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomValidators } from '../../validators/custom-validators';

@Component({
  selector: 'app-upd-rsn-popup',
  templateUrl: './upd-rsn-popup.component.html'
})
export class UpdRsnPopupComponent implements OnInit {
  @Output()
  updRsn = new EventEmitter<string>();

  formGroup = this.formBuilder.group({
    updRsn: [null, [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(100)]]
  });


  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder) { }

  ngOnInit() { }

  save() {
    this.updRsn.emit(this.formGroup.controls.updRsn.value);
    this.modalRef.hide();
  }

}
