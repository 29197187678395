import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridApi } from 'ag-grid-community';

/**
 * 페이징 컨트롤에 표시할 페이지수 (기본 10)
 * @type {number}
 */
const displayedPages = 10;

/**
 * ag-Grid 커스텀 페이징 컨트롤
 * @description 실제 어플리케이션 구현시 응용 요구사항에 따라 수정하시기 바랍니다.
 * @author
 * @license IDP v1.0.0
 */
@Component({
  selector: 'ag-grid-pagination',
  template: `
    <div class="grid-paginate">
      <ul class="pagination" [class.d-none]="totalPages === 0">
        <li class="paginate-button first" [class.disabled]="currentPage === 0">
          <a (click)="onPagingFirst()">
            <i class="fa fa-angle-double-left"></i>
          </a>
        </li>
        <li class="paginate-button previous" [class.disabled]="currentPage === 0">
          <a (click)="onPagingPrevious()">
            <i class="fa fa-angle-left"></i>
          </a>
        </li>
        <li class="paginate-button" [class.active]="page === currentPage" *ngFor="let page of pages">
          <a (click)="onPaging(page)">{{ page + 1 }}</a>
        </li>
        <li class="paginate-button next" [class.disabled]="currentPage + 1 === totalPages">
          <a (click)="onPagingNext()">
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
        <li class="paginate-button last" [class.disabled]="currentPage + 1 === totalPages">
          <a (click)="onPagingLast()">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </li>
      </ul>
    </div>`
})
export class AgGridPaginationComponent implements OnInit {

  /**
   * Grid API
   * @description 상위 컴포넌트에서 GridApi 객체를 입력받아야 한다.
   */
  @Input() gridApi: GridApi;

  /**
   * 페이지 변경 시 발생하는 이벤트
   */
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();

  /**
   * 페이징 Offset
   */
  offset: number;

  constructor() { }

  ngOnInit(): void { }

  /**
   * 첫 페이지로 이동
   */
  onPagingFirst(): void {
    this.gridApi.deselectAll();
    this.gridApi.paginationGoToFirstPage();
    this.pageChanged.emit(this.currentPage);
  }

  /**
   * 마지막 페이지로 이동
   */
  onPagingLast(): void {
    this.gridApi.deselectAll();
    this.gridApi.paginationGoToLastPage();
    this.pageChanged.emit(this.currentPage);
  }

  /**
   * 다음 페이지로 이동
   */
  onPagingNext(): void {
    this.gridApi.deselectAll();
    this.gridApi.paginationGoToNextPage();
    this.pageChanged.emit(this.currentPage);
  }

  /**
   * 이전 페이지로 이동
   */
  onPagingPrevious(): void {
    this.gridApi.deselectAll();
    this.gridApi.paginationGoToPreviousPage();
    this.pageChanged.emit(this.currentPage);
  }

  /**
   * 해당 페이지로 이동
   * @param {number} page
   */
  onPaging(page: number): void {
    this.gridApi.deselectAll();
    this.gridApi.paginationGoToPage(page);
    this.pageChanged.emit(this.currentPage);
  }

  /**
   * 그리드 로우 카운트 반환
   * @returns {number}
   */
  get rowCount(): number {
    return this.gridApi && this.gridApi.paginationGetRowCount();
  }

  /**
   * 마지막 페이지 여부 반환
   * @returns {boolean}
   */
  get isLastPageFound(): boolean {
    return this.gridApi && this.gridApi.paginationIsLastPageFound();
  }

  /**
   * 페이징 사이즈 반환
   * @returns {number}
   */
  get pageSize(): number {
    return this.gridApi && this.gridApi.paginationGetPageSize();
  }

  /**
   * 현재 페이지(수) 반환
   * @returns {number}
   */
  get currentPage(): number {
    return this.gridApi && this.gridApi.paginationGetCurrentPage();
  }

  /**
   * 전체 페이지(수) 반환
   * @returns {number}
   */
  get totalPages(): number {
    return this.gridApi && this.gridApi.paginationGetTotalPages();
  }

  /**
   * 페이징 컨트롤에 표시할 페이지 카운트 배열 반환
   * @returns {number[]}
   */
  get pages(): number[] {
    if (this.gridApi) {
      this.offset = Math.floor(this.currentPage / displayedPages) * displayedPages;
      if (this.offset + displayedPages < this.totalPages) {
        return Array(displayedPages)
          .fill(0)
          .map((x, i) => i + this.offset);
      } else {
        return Array(this.totalPages - this.offset)
          .fill(0)
          .map((x, i) => i + this.offset);
      }
    }
  }
}
