import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from as fromPromise, Observable } from 'rxjs';
import { default as Swal, SweetAlertOptions } from 'sweetalert2';


// export const SwalDefaultsOptions = new InjectionToken<SweetAlertOptions>('SwalDefaultsOptions');

/**
 * 메시지 (SweetAlert2) 기본 옵션
 */
const defaultOptions: SweetAlertOptions = {
  showCancelButton: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    denyButton: 'btn btn-default',
    cancelButton: 'btn btn-default'
  }
};

/**
 * 메시지 (SweetAlert2) 서비스
 * @description
 * 메시지 (SweetAlert2)를 출력하는 서비스
 * @author
 * @license IDP v1.0.0
 */
@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor(private translateService: TranslateService) {
  }

  /**
   * Swal 기본 설정
   * @param {SweetAlertOptions} options2
   * @returns {SweetAlertOptions}
   */
  combineOptions(options1: SweetAlertOptions, options2: SweetAlertOptions = {}, options3: SweetAlertOptions = {}): SweetAlertOptions {
    return {
      ...options1,
      ...options2,
      ...options3,
      customClass: {
        ...options1.customClass,
        ...options2.customClass,
        ...options3.customClass
      }
    };
  }

  /**
   * Success 메시지 출력
   * @param {string} text
   * @param {string} title
   * @param {boolean} showCancelButton
   * @returns {Observable<boolean>}
   */
  success(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    showCancelButton: boolean = false,
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-success' },
      showCancelButton,
      confirmButtonText: this.translateService.instant('btn_conf')
    }, options));
  }

  /**
   * Error 메시지 출력
   * @param {string} text
   * @param {string} title
   * @param {boolean} showCancelButton
   * @returns {Observable<boolean>}
   */
  error(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    showCancelButton: boolean = false,
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-error' },
      showCancelButton,
      confirmButtonText: this.translateService.instant('btn_conf')
    }, options));
  }

  errorWithList(
    text: string,
    list: any[],
    nameField: string,
    reasonField: string,
    title: string = this.translateService.instant('title_msg'),
    showCancelButton: boolean = false,
    options?: SweetAlertOptions
  ) {
    if (list.length) {
      text += '<br><table class="table-detail mt-10">';

      list.forEach(item => {
        text += '<tr><th>' + item[nameField] + '</th><td>' + item[reasonField] + '</td></tr>';
      });

      text += '</table>';
    }

    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-error' },
      showCancelButton,
      confirmButtonText: this.translateService.instant('btn_conf')
    }, options));
  }

  /**
   * Warning 메시지 출력
   * @param {string} text
   * @param {string} title
   * @param {boolean} showCancelButton
   * @returns {Observable<boolean>}
   */
  warning(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    showCancelButton: boolean = false,
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-warning' },
      showCancelButton,
      confirmButtonText: this.translateService.instant('btn_conf')
    }, options));
  }

  /**
   * Info 메시지 출력
   * @param {string} text
   * @param {string} title
   * @param {boolean} showCancelButton
   * @returns {Observable<boolean>}
   */
  info(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    showCancelButton: boolean = false,
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-info' },
      showCancelButton,
      confirmButtonText: this.translateService.instant('btn_conf')
    }, options));
  }

  /**
   * Question 메시지 출력
   * @param {string} text
   * @param {string} title
   * @returns {Observable<boolean>}
   */
  question(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-question' },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('btn_yes'),
      cancelButtonText: this.translateService.instant('btn_no')
    }, options));
  }

  /**
   * Dropdown List를 통한 선택
   * @param {string} text
   * @param {string} title
   * @returns {Observable<boolean>}
   */
  select(
    text: string,
    inputOptions: any,
    title: string = this.translateService.instant('title_msg'),
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-question' },
      showCancelButton: true,
      input: 'select',
      inputOptions,
      confirmButtonText: this.translateService.instant('btn_conf'),
      cancelButtonText: this.translateService.instant('btn_cacl')
    }, options));
  }

  /**
   * Input을 통한 입력
   * @param {string} text
   * @param {string} title
   * @returns {Observable<boolean>}
   */
  input(
    text: string,
    title: string = this.translateService.instant('title_msg'),
    options?: SweetAlertOptions
  ) {
    return this.swal(text, title, this.combineOptions({
      customClass: { popup: 'swal-question' },
      showCancelButton: true,
      input: 'text',
      confirmButtonText: this.translateService.instant('btn_conf'),
      cancelButtonText: this.translateService.instant('btn_cacl')
    }, options));
  }

  /**
   * Invoke SweetAlert2
   * @param text
   * @param title
   * @param type
   * @param {SweetAlertOptions} options
   * @returns {Observable<boolean>}
   */
  private swal(text, title, options: SweetAlertOptions) {
    return fromPromise(
      Swal.fire({
        titleText: title,
        html: text,
        ...this.combineOptions(defaultOptions, options)
      })
    );
  }
}
