import { Injectable, OnDestroy } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SessionService } from '../services';

@Injectable({ providedIn: 'root' })
export class WebsocketService implements OnDestroy {
  constructor(
    private rxStompService: RxStompService,
    private sessionService: SessionService
  ) {
    // super();
    // this.config = config;
    this.sessionService.isAuthenticated$.pipe(distinctUntilChanged()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.rxStompService.deactivate();
      }
      else if (isAuthenticated) {
        this.rxStompService.activate();
      }
    });
  }

  ngOnDestroy() {
    this.rxStompService.deactivate();
  }

  getNewSubId() {
    return this.sessionService.getloginId() + Math.random().toString(36).substr(2, 9);
  }

  ping() {
    return interval(60 * 1000).subscribe(() => this.rxStompService.publish({ destination: '/ping', body: 'ping', retryIfDisconnected: false }));
  }

  subscribe(destination: string) {
    return this.rxStompService.watch(destination);
  }

  get connected$() {
    return this.rxStompService.connected$;
  }

  get connectionState$() {
    return this.rxStompService.connectionState$;
  }
}
