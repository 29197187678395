import { Injectable } from '@angular/core';

/**
 * 어플리케이션 관리 서비스
 *
 * 어플리케이션 레이아웃 및 각종 토글 상태를 관리하는 서비스
 * 어플리케이션 구현시 응용요구사항에 부합하도록 재구현하시기 바랍니다.
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class AppService {
  /**
   * 검색 오버레이 표시 여부
   * @type {boolean}
   * @private
   */
  private _isShowAppSearch: boolean = false;

  /**
   * Emotion 검색 오버레이 표시 여부
   * @type {boolean}
   * @private
   */
  private _isShowAppEmotionSearch: boolean = false;

  constructor() {}

  get isShowAppSearch(): boolean {
    return this._isShowAppSearch;
  }

  set isShowAppSearch(value: boolean) {
    this._isShowAppSearch = value;
  }

  get isShowAppEmotionSearch(): boolean {
    return this._isShowAppEmotionSearch;
  }

  set isShowAppEmotionSearch(value: boolean) {
    this._isShowAppEmotionSearch = value;
  }
}
