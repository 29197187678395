import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, MenuService } from '@common/core/services';
import { Subscription } from 'rxjs';

const DEFAULT_CONTAINER_CLASS = 'container';

@Component({
  selector: 'app-content',
  templateUrl: './content-layout.component.html'
})
export class ContentLayoutComponent implements OnInit, OnDestroy {

  /**
   * 사이드바 숨김 여부
   * @type {boolean}
   */
  suppressNav: boolean = false;

  /**
   * 컨테이너 클래스
   * @type {string}
   */
  container: string = DEFAULT_CONTAINER_CLASS;

  /**
   * 네비게이션 데이터
   * @type {any}
   */
  navigation: any;

  /**
   * 사이드바 유형 (메뉴, 트리)
   * @type {string}
   */
  type: string;

  subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    const { data } = this.route.snapshot;

    // 컨테이너 유형
    // container-fluid: For a full width container, spanning the entire width of the viewport.
    // container: Containers are the most basic layout element in Bootstrap and are required when using our default grid system
    this.container = data.container ? data.container : DEFAULT_CONTAINER_CLASS;

    // 사이드 바 표시여부
    this.suppressNav = !!data.suppressNav;

    // 메뉴 변경 시 사이드바 메뉴 재조회
    this.subscription.add(this.menuService.onMenuChange.subscribe(() => {
      this.navigation = this.dataService.getCurrentMenu().menuId ? this.getNavigation(this.dataService.getCurrentMenu().menuId) : [];
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * 네비게이션 데이터 조회
   * @param {string} menuId
   * @returns {any}
   */
  private getNavigation(menuId: string): any {
    return this.menuService.findByMenuId(menuId);
  }
}
