import { Injectable } from '@angular/core';
import { ApiService } from '@common/core/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SysAuthService {

  private path = '/v1/api/idp/sys/sys/auth';

  constructor(private apiService: ApiService) { }

  getSysAuthUnitList(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getSysAuthUnitList'), params);
  }

  getList(params: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getList'), params);
  }

  multiInsert(params: any): Observable<any> {
    return this.apiService.post(this.path.concat('/multiInsert'), params);
  }

  multiDelete(params: any): Observable<any> {
    return this.apiService.post(this.path.concat('/multiDelete'), params);
  }

}
