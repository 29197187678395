import { Injectable } from '@angular/core';
import { User } from '@common/core/models';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from './notification.service';

/**
 * 세션관리 서비스
 * @description 사용자 세션을 관리하는 서비스
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class SessionService {

  // 로그인 사용자 정보

  /**
   * 로그인 사용자 정보 Subject
   */
  private currentUserSubject = new BehaviorSubject<User>({} as User);

  /**
   * 로그인 사용자 정보
   * @type {Observable<any>}
   */
  public currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  // 인증 여부
  isAuthenticated = false;

  /**
   * 인증 여부 Subject
   */
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

  /**
   * 인증여부 Observable
   * @type {Observable<boolean>}
   */
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private localeService: BsLocaleService
  ) {
    this.restoreSession();
  }

  /**
   * 사용자 세션 정보 초기화 (스토리지에서 복원)
   */
  restoreSession() {
    const user = this.localStorageService.retrieve('user');

    if (user) {
      this.currentUserSubject.next(user);
      this.isAuthenticated = true;
      this.isAuthenticatedSubject.next(true);
      moment.tz.setDefault(user.timeZoneId);
      this.translateService.use(user.lnggCd);
      this.localeService.use(user.lnggCd);
    } else {
      this.isAuthenticated = false;
      this.isAuthenticatedSubject.next(false);
    }
  }

  /**
   * 세션정보 저장
   * @param {User} user 사용자정보
   */
  storeSession(user: User) {
    // 현재 사용자 정보를 Observable 내에 저장한다.
    this.currentUserSubject.next(user);

    // 인증여부를 true 로 갱신한다.
    this.isAuthenticated = true;
    this.isAuthenticatedSubject.next(true);

    // 사용자 정보를 로컬스토리지에 저장한다.
    this.localStorageService.store('user', user);

    // 사용자가 설정한 타임존을 기본값으로 설정한다.
    moment.tz.setDefault(user.timeZoneId);

    // 사용자가 설정한 언어로 변경한다.
    this.translateService.use(user.lnggCd);
    this.localeService.use(user.lnggCd);
  }

  /**
   * 세션 정보 파기
   */
  clearSession() {
    this.localStorageService.clear('user');
    this.sessionStorageService.clear();

    // 현재 사용자 정보를 빈 {} 객체로 갱신한다.
    this.currentUserSubject.next({} as User);

    // 인증여부를 false 로 갱신한다.
    this.isAuthenticated = false;
    this.isAuthenticatedSubject.next(false);
    // this.isAuthenticatedSubject.complete(); // complete() 하면 다시 로그인할 때 문제 발생. 다른 문제 발생 시 이 부분 확인 필요

    this.notificationService.clear();
  }

  /**
   * 사용자 세션 정보 반환
   * @returns {User}
   */
  getUser(): User {
    return this.currentUserSubject.value;
  }

  /**
   * 사용자 명 반환
   * @returns {string}
   */
  getUserNm(): string {
    return this.currentUserSubject.value ? this.currentUserSubject.value.userNm : '';
  }

  /**
   * 사용자 ID 반환
   * @returns {string}
   */
  getloginId(): string {
    return this.currentUserSubject.value ? this.currentUserSubject.value.loginId : '';
  }

  getLnggCd() {
    return this.currentUserSubject.value?.lnggCd || 'en';
  }
}

