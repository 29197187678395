import { base64 } from './base64';
import { creditCard } from './credit-card';
import { date } from './date';
import { dateISO } from './date-ios';
import { digits } from './digits';
import { email } from './email';
import { equal } from './equal';
import { equalTo } from './equal-to';
import { gt } from './greater-than';
import { gte } from './greater-than-equal';
import { json } from './json';
import { lt } from './less-than';
import { lte } from './less-than-equal';
import { max } from './max';
import { maxDate } from './max-date';
import { min } from './min';
import { minDate } from './min-date';
import { noWhitespace } from './no-whitespace';
import { notEqual } from './not-equal';
import { notEqualTo } from './not-equal-to';
import { number } from './number';
import { password } from './password';
import { passwordCheck } from './password-check';
import { passwordMatch } from './password-match';
import { passwordUseWord } from './password-use-word';
import { phone } from './phone';
import { range } from './range';
import { rangeLength } from './range-length';
import { url } from './url';
import { uuid } from './uuid';
/**
 * @fileOverview 커스텀 밸리데이터
 * @see https://github.com/yuyang041060120/ng2-validation/blob/master/README.md
 * @author
 * @license MIT
 */
export const CustomValidators: any = {
  base64,
  creditCard,
  date,
  dateISO,
  digits,
  email,
  equal,
  equalTo,
  gt,
  gte,
  json,
  lt,
  lte,
  max,
  maxDate,
  min,
  minDate,
  notEqual,
  notEqualTo,
  number,
  phone,
  range,
  rangeLength,
  url,
  uuid,
  password,
  passwordMatch,
  noWhitespace,
  passwordCheck,
  passwordUseWord
};
