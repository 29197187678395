import { NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/**
 * Import core module
 */
import { CoreModule } from '@common/core/core.module';
import { SharedRootModule } from '@common/shared/shared-root.module';
/**
 * Import shared module
 */
import { SharedModule } from '@common/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
/**
 * Import reducers
 */
import { NgxWebstorageModule } from 'ngx-webstorage';
/**
 * Declare app component
 */
import { AppComponent } from './app.component';
/**
 * Import routing module
 */
import { AppRoutingModule } from './app.routing';
/**
 * Import frameset module
 */
import { FramesetModule } from './frameset/frameset.module';
/**
 * Import login module
 */
import { LoginModule } from './login/login.module';
import { NotFoundComponent, RedirectComponent } from './pages';



const APP_PAGES_COMPONENTS = [
  NotFoundComponent,
  RedirectComponent
];






/**
 * 어플리케이션 루트 모듈
 * @description
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    // import angular common module
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    NgxWebstorageModule.forRoot({ prefix: 'idp', separator: '-' }),

    // import core
    CoreModule,

    // import shared module
    SharedModule,
    SharedRootModule,

    // import features module
    FramesetModule,
    LoginModule,

    // import loading module
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    APP_PAGES_COMPONENTS
  ],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  bootstrap: [AppComponent]
})
export class AppModule { }
