import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@app/common/core/services';
import { AuthKeyService } from '@app/common/core/services/auth-key/auth-key.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomValidators } from '../../validators/custom-validators';
import { LoginPwService } from '../login-pw-upd-popup/login-pw.service';

@Component({
  selector: 'app-login-pw-reset-popup',
  templateUrl: './login-pw-reset-popup.component.html'
})
export class LoginPwResetPopupComponent implements OnInit {
  @Input() lnggCd: string;
  @Input() smsEnabled: boolean;
  @Input() emailEnabled: boolean;

  inputForm = this.formBuilder.group({
    authWayCd: [null, Validators.required],
    userNm: [null, [Validators.required, CustomValidators.noWhitespace]],
    loginId: [null, [Validators.required, CustomValidators.noWhitespace]],
    userEmail: [null, [Validators.required, Validators.email, CustomValidators.noWhitespace]],
    userMobno: [null, [Validators.required, CustomValidators.noWhitespace]],
    authKey: [{ value: null, disabled: true }, [Validators.required, CustomValidators.noWhitespace]]
  });

  dto: any = {};

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private authKeyService: AuthKeyService,
    private loginPwService: LoginPwService
  ) { }

  ngOnInit() {
    if (this.smsEnabled) {
      this.inputForm.controls.authWayCd.setValue('SY007SMS');
    }
    else if (this.emailEnabled) {
      this.inputForm.controls.authWayCd.setValue('SY007MAI');
    }
  }

  sendAuthKey() {
    this.dto.authDvCd = 'SY006001';
    this.dto.authWayCd = this.inputForm.controls.authWayCd.value;
    this.dto.userNm = this.inputForm.controls.userNm.value;
    this.dto.loginId = this.inputForm.controls.loginId.value;
    if (this.dto.authWayCd === 'SY007SMS') this.dto.userMobno = this.inputForm.controls.userMobno.value;
    if (this.dto.authWayCd === 'SY007MAI') this.dto.userEmail = this.inputForm.controls.userEmail.value;
    this.dto.lnggCd = this.lnggCd;

    this.authKeyService.send(this.dto).subscribe(res => {
      if (res.rsltCd === '00') {
        this.messageService.success(this.translateService.instant('msg_suss_00014'));
        this.inputForm.controls.authWayCd.disable();
        this.inputForm.controls.userNm.disable();
        this.inputForm.controls.loginId.disable();
        this.inputForm.controls.userEmail.disable();
        this.inputForm.controls.userMobno.disable();
        this.inputForm.controls.authKey.enable();
      }
      else if (res.rsltCd === '02') {
        this.messageService.error(this.translateService.instant('msg_warn_00094'));
      }
    });
  }

  confirm() {
    this.dto.authKey = this.inputForm.controls.authKey.value;

    this.loginPwService.reset(this.dto).subscribe(res => {
      if (res.rsltCd === '00') {
        this.messageService.success(this.translateService.instant('msg_suss_00015')).subscribe(() => this.modalRef.hide());
      }
      else {
        this.messageService.error(this.translateService.instant('msg_warn_00095'));
      }
    });
  }

}
