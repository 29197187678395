import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotifyService, SessionService } from '../services';


/**
 * HTTP 인터셉터
 * @description HTTP 인터셉터
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class HttpCommonInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notifyService: NotifyService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private injector: Injector
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loading = request.body && !request.body.withoutLoading;
    if (request.body) delete request.body.withoutLoading;

    const req = request.clone({
      headers: request.headers,
      withCredentials: true
    });

    if (loading) this.spinnerService.show();

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (loading) this.spinnerService.hide();
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (loading) this.spinnerService.hide();

            if (err.status === 401) {
              this.injector.get(SessionService).clearSession(); // SessionService를 직접 주입하면 순환 오류 발생
              this.modalService['loaders'].forEach(loader => this.modalService.hide(loader.instance.id));

              this.router.navigateByUrl('/login');

              if (this.router.url !== '/' || !err.url.match('/v1/api/auth')) {
                this.notifyService.error(this.translateService.instant('msg_warn_00119'), this.translateService.instant('lbl_login_expired'));
              }
            }
            else if (err.status !== 404) {
              if (err.url && err.url.indexOf('/login') === -1 &&
                err.url.indexOf('/logout') === -1 &&
                err.url.indexOf('/ws/') === -1 &&
                err.url.indexOf('/v1/api/auth') === -1 &&
                err.url.indexOf('/lgid/Init') === -1) {

                this.notifyService.warning(
                  err.url ? err.url : '처리중 오류가 발생하였습니다.',
                  `HTTP ${err.status}`
                );
              }
            }
          }
          return throwError(err);
        }
      )
    );
  }
}
