import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { CurrentMenu } from '../../models/global/current-menu.model';

/**
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class DataService {
  private _data: any = {};

  constructor(
    private sessionStorageService: SessionStorageService
  ) { }

  setCurrentMenu(value: CurrentMenu) {
    this.sessionStorageService.store('currentMenu', value);
  }

  getCurrentMenu(): CurrentMenu {
    return this.sessionStorageService.retrieve('currentMenu');
  }

  getData(key?: string): any {
    return key ? this._data[key] : this._data;
  }

  setData(data: any, key?: string) {
    key ? this._data[key] = data : this._data = data;
  }

  clearData() {
    this._data = {};
  }

  clearCurrentMenu() {
    this.sessionStorageService.clear('currentMenu');
  }
}
