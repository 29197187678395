import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DragulaModule } from 'ng2-dragula';
import { AlertModule } from 'ngx-bootstrap/alert';
import { defineLocale, enGbLocale, jaLocale, koLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '../core/core.module';
// ag-grid custom components
import { GridButtonRendererComponent, GridDatepickerComponent, GridLabelIconRendererComponent, GridMultiSelectComponent, GridMultiSelectInputComponent, GridPopupRendererComponent, GridSelectComponent, GridSelectEditorComponent, GridSwitchButtonRendererComponent, GridTextIconRendererComponent, GridTimepickerComponent, GridValidationTooltipComponent } from './libs/ag-grid';


const NGX_BOOTSTRAP_MODULES = [
  AlertModule.forRoot(),
  BsDropdownModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
  TabsModule.forRoot(),
  TimepickerModule.forRoot(),
  TooltipModule.forRoot(),
  TypeaheadModule.forRoot(),
  PopoverModule.forRoot()
];



const AG_GRID_FRAMEWORK_COMPONENTS = [
  GridDatepickerComponent,
  GridMultiSelectComponent,
  GridPopupRendererComponent,
  GridButtonRendererComponent,
  GridSelectComponent,
  GridMultiSelectInputComponent,
  GridLabelIconRendererComponent,
  GridTimepickerComponent,
  GridSelectEditorComponent,
  GridSwitchButtonRendererComponent,
  GridTextIconRendererComponent,
  GridValidationTooltipComponent
];

/**
 * ngx-bootstrap 로케일 설정
 */
defineLocale('ko', koLocale);
defineLocale('en', enGbLocale);
defineLocale('jp', jaLocale);

/**
 * ngx-bootstrap 데이트피커 로케일 설정
 * @returns {BsDatepickerConfig}
 */
const getDatepickerConfig = (): BsDatepickerConfig =>
  Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY.MM.DD'
  });

/**
 * ngx-bootstrap 데이트레인지피커 로케일 설정
 * @returns {BsDatepickerConfig}
 */
const getBsDaterangepickerConfig = (): BsDaterangepickerConfig =>
  Object.assign(new BsDaterangepickerConfig(), {
    rangeInputFormat: 'YYYY.MM.DD'
  });

/**
 * 공통 루트 모듈
 * @description
 * 기능 모듈에서 사용하는 공유 모듈 중 루트모듈에 임포되어야 하는 모듈들을 정리
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    // import ag-Grid module into root
    AgGridModule.withComponents([
      ...AG_GRID_FRAMEWORK_COMPONENTS
    ]),

    // import ngx-bootstrap module into root
    ...NGX_BOOTSTRAP_MODULES,

    // import vendor module into root
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    DragulaModule.forRoot()
  ],
  providers: [
    {
      provide: BsDatepickerConfig,
      useFactory: getDatepickerConfig
    },
    {
      provide: BsDaterangepickerConfig,
      useFactory: getBsDaterangepickerConfig
    },
    // {
    //   provide: NG_SELECT_DEFAULT_CONFIG,
    //   useValue: {
    //     placeholder: '선택',
    //     notFoundText: '결과가 없습니다.',
    //     addTagText: '항목추가',
    //     typeToSearchText: '검색어를 입력하여 주십시오.',
    //     loadingText: '조회중입니다.',
    //     clearAllText: '모두삭제'
    //   }
    // },
  ]
})
export class SharedRootModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'SharedRootModule');
  }
}

export const throwIfAlreadyLoaded = (parentModule: any, moduleName: string): any => {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import SharedRootModule in the AppModule only.`);
  }
};
