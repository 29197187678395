/**
 * 핵심 모듈 중복 임포트 방지
 * [STYLE 04-11](https://angular.io/styleguide#04-12)
 *
 * @name throwIfAlreadyLoaded
 * @param {any} parentModule Parent module.
 * @param {string} moduleName - Module name.
 * @returns {any}
 * @see [Angular docs - Prevent reimport of the CoreModule](https://angular.io/docs/ts/latest/guide/ngmodule.html#prevent-reimport)
 */
export const throwIfAlreadyLoaded = (parentModule: any, moduleName: string): any => {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
};
