<header class="app-header navbar">
  <div class="navbar-item">
    <a class="navbar-brand" [class.navbar-brand-home]="isHome" [routerLink]="['/']">
    </a>
    <div class="nav-item d-md-down-none search-wrap" [class.active]="isSearchOpen">
      <a class="nav-link search" (click)="toggleSearchInput($event)"><i aria-hidden="true">검색</i></a>
      <input id="headerSearch" type="search" class="form-control" placeholder="Search" [typeahead]="navSearchList" [typeaheadOptionField]="'menuNm'" [(ngModel)]="navSearch"
        (typeaheadOnSelect)="moveTo($event)" (focus)="isSearchOpen = true" (blur)="isSearchOpen = false" container="body">
    </div>    
  </div>
  <div class="navbar-item">
    <ul class="nav navbar-quick">
      <li #quicklinkMenu="bs-dropdown" dropdown [insideClick]="true">
        <button class="navbar-toggler" type="button" dropdownToggle aria-controls="dropdown-quicklink">
          <i class="fa fa-star" aria-hidden="true"></i>
          <span>즐겨찾기</span>
        </button>
        <div id="dropdown-quicklink" class="dropdown-menu quicklink-menu">
          <div class="quicklink-header">{{ 'title_favorite' | translate }}</div>
          <div class="quicklink-body">
            <ul>
              <li *ngFor="let navigation of menuService.menuList">
                <ul *ngIf="navigation.items.length > 0">
                  <li *ngFor="let item of navigation.items">
                    <div *ngIf="item.isQuicklink" class="quicklink-item">
                      <button *ngIf="item?.children?.length === 0" class="fav-btn" [class.active]="item.isQuicklink" type="button" (click)="quicklinkService.toggleQuicklink(item.id)"><i class="fa fa-star" aria-hidden="true"></i></button>
                      <a [routerLink]="item?.children?.length > 0 ? item.children[0].url : item.url" (click)="quicklinkMenu.hide()"><span [title]="item.name">{{ item.name }}</span></a>
                    </div>
                    <ul *ngIf="item.children?.length > 0">
                      <li *ngFor="let child of item.children">
                        <div *ngIf="child.isQuicklink" class="quicklink-item">
                          <button class="fav-btn" [class.active]="child.isQuicklink" type="button" (click)="quicklinkService.toggleQuicklink(child.id)"><i class="fa fa-star" aria-hidden="true"></i></button>
                          <a [routerLink]="child.url" (click)="quicklinkMenu.hide()"><span [title]="child.name">{{ child.name }}</span></a>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li #fullMenu="bs-dropdown" dropdown [insideClick]="true">
        <button class="navbar-toggler" id="button-basic" type="button" aria-controls="dropdown-basic" dropdownToggle>
          <i class="fa all" aria-hidden="true"></i>
          <span>전체메뉴</span>
        </button>
        <!--풀다운 메뉴-->
        <div class="navbar-full" id="dropdown-basic" aria-labelledby="button-basic">
          <div class="inner">
            <div class="fullmenu">
              <ul>
                <li class="list" *ngFor="let navigation of menuService.menuList">
                  <h4>{{ navigation.title }}</h4>
                  <ng-container *ngFor="let item of navigation.items">
                    <ul *ngIf="item.children?.length === 0">
                      <li>
                        <button class="fav-btn" [class.active]="item.isQuicklink" type="button" (click)="quicklinkService.toggleQuicklink(item.id)"><i class="fa fa-star" aria-hidden="true"></i></button>
                      </li>
                      <li>
                        <a [routerLink]="item.url" (click)="fullMenu.hide()">{{ item.name }}</a>
                      </li>
                    </ul>
                    <ng-container *ngIf="item.children?.length > 0">
                      <ul *ngFor="let child of item.children">
                        <li>
                          <button class="fav-btn" [class.active]="child.isQuicklink" type="button" (click)="quicklinkService.toggleQuicklink(child.id)"><i class="fa fa-star" aria-hidden="true"></i></button>
                        </li>
                        <li>
                          <a [routerLink]="child.url" (click)="fullMenu.hide()">{{ child.name }}</a>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </li>
              </ul>
            </div>
            <!-- <ul class="quickmenu">
              <li>
                <button type="button"><i class="notice" aria-hidden="true"></i><em>공지사항</em></button>
              </li>
              <li>
                <button type="button"><i class="help" aria-hidden="true"></i><em>도움말</em></button>
              </li>
              <li>
                <button type="button"><i class="data" aria-hidden="true"></i><em>자료실</em></button>
              </li>
              <li>
                <button type="button"><a routerLink="/guide"><em>Guide</em></a></button>
              </li>
            </ul> -->
          </div>
        </div>
      </li>
    </ul>
    <ul class="nav main-nav navbar-nav d-md-down-none">
      <li class="nav-item" *ngFor="let navigation of navigationList">
        <a class="nav-link" [routerLink]="[navigation.url]" [class.active]="navigation.isActive" appHeaderIndicator
          (hover)="animateIndicator($event)">{{ navigation.title }}</a>
      </li>
      <li #indicator class="app-header-indicator"></li>
    </ul>
    <ul class="navbar-info d-md-down-none">
      <li>
        <button class="navbar-btn notice" type="button" appAsideMenuToggler>
          <span>알림</span>
          <strong class="num">{{notificationService.count}}</strong>
        </button>
      </li>
      <li class="dropdown" dropdown placement="bottom right" container="body">
        <a class="navbar-btn modify" href="#" role="button" dropdownToggle (click)="false">
          <span></span>
        </a>
        <div id="dropdown-setup" class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <a class="dropdown-item">
            <img class="avatar" src="{{ user?.imagePath }}" alt="{{ user?.loginId + '@lgcns.com'}}" default="assets/images/avatars/avatar.gif">
            {{ user?.userNm }} {{ user?.loginId }}
          </a>
          <a class="dropdown-item" (click)="popupMyInfo()">
            <i class="icon-note" aria-hidden="true"></i>
            <span>{{ 'btn_indvl_setup' | translate }}</span>
          </a>
          <a class="dropdown-item" (click)="popupLoginPwUpd()">
            <i class="icon-lock" aria-hidden="true"></i>
            <span>{{ 'btn_pw_upd' | translate }}</span>
          </a>
          <a class="dropdown-item" (click)="logout()">
            <i class="icon-logout" aria-hidden="true"></i>
            <span>{{ 'btn_logout' | translate }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</header>

