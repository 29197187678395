import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

/**
 * 권한 체크 서비스
 *
 * 메뉴 및 특정 기능의 사용자 권한을 체크하는 서비스
 * 본 샘플 웹사이트에서는 무조건 true 를 반환하도록 되어있다.
 * 어플리케이션 구현시 용도에 맞게 수정이 필요하다.
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class AuthService {

  private resourceUrl = `/v1/api/auth`;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 메뉴 권한 체크
   * @param params
   * @returns {Observable<boolean>}
   */
  hasMenuAuthority(menuId: string, url: string, currentDtm: moment.Moment): Observable<any> {
    return this.http.post(`${this.resourceUrl}/hasMenuAuthority`, { previousMenuId: menuId, url, currentDtm });
  }
}
