import { Component, OnInit } from '@angular/core';
import { HelpdeskService } from '@app/common/core/services';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './app-sidebar-footer.component.html',
  styles: [`
    p i {
      margin-right: 7px;
      display: inline-block;
      width: 13px;
      height: 15px;
      background: url("/assets/images/icon/login.png") no-repeat 0 -7px;
      vertical-align: middle;
    }
    p i.phone {
      background-position-x: -70px;
      background-position-y: -6px;
    }
    p i.email {
      background-position-x: -99px;
      background-position-y: -4px;
    }
  `]
})
export class AppSidebarFooterComponent implements OnInit {
  phone: string;
  email: string;

  constructor(
    private helpdeskService: HelpdeskService
  ) { }

  ngOnInit() {
    this.helpdeskService.load().subscribe(res => {
      this.phone = res.phone;
      this.email = res.email;
    });
  }
}
