import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * 리다이렉트 컴포넌트
 * @description 리다이렉트를 처리하는 컴포넌트
 * @author
 * @license IDP v1.0.0
 */
@Component({
  selector: 'app-redirect',
  template: './redirect.component.html'
})
export class RedirectComponent implements OnInit {

  urlPath: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.urlPath = route.snapshot.params['path'];
  }

  ngOnInit() {
    this.router.navigateByUrl(this.urlPath);
  }
}
