import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { AuthGuard } from './guards';
import { ApiService } from './http';
import { HttpCommonInterceptor } from './interceptors';
import { throwIfAlreadyLoaded } from './module-import.guard';
// Import Services
import { AppService, AuthService, CommonCodeService, DataService, FormHelper, LoginService, MenuService, SessionService, WindowRef } from './services';
import { QuickSearchService } from './services/quick-search/quick-search.service';
import { stompConfig, WebsocketService } from './websocket';


/**
 * 다국어 리소스 파일 경로 지정
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 */
export const createTranslateLoader = (http: HttpClient) => {
  // 다국어 파일의 확장자와 경로를 지정
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


/**
 * 핵심모듈 서비스 주입
 */
const CORE_SERVICES = [
  AuthService,
  LoginService,
  AppService,
  CommonCodeService,
  MenuService,
  SessionService,
  WindowRef,
  FormHelper,
  WebsocketService,
  ApiService,
  DataService
];

/**
 * 핵심 모듈
 * @description 어플리케이션 전역에서 사용하는 핵심 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    ...CORE_SERVICES,
    {
      provide: InjectableRxStompConfig,
      useValue: stompConfig
    },

    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCommonInterceptor,
      multi: true
    },
    QuickSearchService
  ]
})
export class CoreModule {
  /**
   * @see [Angular docs - CoreModule.forRoot](https://angular.io/docs/ts/latest/guide/ngmodule.html#core-for-root)
   * @returns {ModuleWithProviders}
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
