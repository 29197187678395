import { Injectable } from '@angular/core';
import { ApiService } from '../../http';

@Injectable({ providedIn: 'root' })
export class AppConfService {

  path = '/v1/api/idp/sys/app/conf';

  constructor(
    private apiService: ApiService
  ) { }

  getList(params) {
    return this.apiService.post(this.path.concat('/getList'), params);
  }
}