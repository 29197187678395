import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html'
})
export class AppSidebarComponent {

  /**
   * 네비게이션 정보 (메뉴정보)
   */
  @Input() navigation: any;

  /**
   * 네비게이션 유형
   * @description
   * - 기본: 아코디언형 메뉴
   * - tree: 트리형 메뉴
   */
  @Input() type: string;

  /**
   * 사이드바 숨김 여부
   * @type {boolean}
   */
  @Input() suppressNav: boolean = false;
}
