import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appHeaderIndicator]'
})
export class HeaderDirective {
  @Output() hover: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  @HostListener('mouseover', ['$event'])
  onMouseover($event: any) {
    $event.preventDefault();
    this.hover.emit($event);
  }
}

export const HEADER_DIRECTIVES = [
  HeaderDirective
];
