import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-loading',
  template: `<i *ngIf="isLoading(params)" class="fa fa-spinner fa-spin"></i><span *ngIf="!isLoading(params)">{{params.value}}</span>`
})
export class GridLoadingRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  isLoading: (params: ICellRendererParams) => boolean;

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.isLoading = params.colDef.cellRendererParams.isLoading;
  }

  refresh() {
    return false;
  }
}
