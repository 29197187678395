import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoctFavoriteComponent } from '@app/common/shared/popup/loct-popup/loct-favorite/loct-favorite.component';
import { LoctPopupComponent } from '@app/common/shared/popup/loct-popup/loct-popup.component';
import { LoctTreeComponent } from '@app/common/shared/popup/loct-popup/loct-tree/loct-tree.component';
import { ComService } from '@app/contents/org/com/com.service';
import { NgxModalDraggableDirective } from '@app/frameset/directives/draggable';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../core/http';
import { AcHdayMgntComponent } from './component/ac-hday-mgnt/ac-hday-mgnt.component';
import { MultiAppotComponent } from './component/multi-appot/multi-appot.component';
import { MultiSelectComponent } from './component/multi-select/multi-select.component';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { TempcardLendNewComponent } from './component/tempcard-lend-new/tempcard-lend-new.component';
import { TempcardReturnNewComponent } from './component/tempcard-return-new/tempcard-return-new.component';
import { TypeaheadSelectComponent } from './component/typeahead-select/typeahead-select.component';
import { AcHdayNewPopupComponent } from './popup/ac-hday-new-popup/ac-hday-new-popup.component';
import { AccsLevelComponent } from './popup/accs-level/accs-level.component';
import { DeptFavComponent } from './popup/dept-fav/dept-fav.component';
import { DeptFavService } from './popup/dept-fav/deptr-fav.service';
import { DeptFavoritePopupComponent } from './popup/dept-favorite-popup/dept-favorite-popup.component';
import { DeptFavoriteComponent } from './popup/dept-popup/dept-favorite/dept-favorite.component';
import { DeptPopupComponent } from './popup/dept-popup/dept-popup.component';
import { DeptSearchRsltComponent } from './popup/dept-popup/dept-search-rslt/dept-search-rslt.component';
import { DeptTreeComponent } from './popup/dept-popup/dept-tree/dept-tree.component';
import { DeviceDoorControlPopupComponent } from './popup/device-door-control-popup/device-door-control-popup.component';
import { DeviceFavoriteComponent } from './popup/device-popup/device-favorite/device-favorite.component';
import { DevicePopupComponent } from './popup/device-popup/device-popup.component';
import { DeviceSearchRsltComponent } from './popup/device-popup/device-search-rslt/device-search-rslt.component';
import { DeviceTreeComponent } from './popup/device-popup/device-tree/device-tree.component';
import { LoctGrpDeviceComponent } from './popup/device-popup/loct-grp-device/loct-grp-device.component';
import { EvPopupComponent } from './popup/ev-popup/ev-popup.component';
import { EvSearchRsltComponent } from './popup/ev-popup/ev-search-rslt/ev-search-rslt.component';
import { EvTreeComponent } from './popup/ev-popup/ev-tree/ev-tree.component';
import { FileDownloadSettingPopupComponent } from './popup/file-download-setting-popup/file-download-setting-popup.component';
import { FileImportPopupComponent } from './popup/file-import-popup/file-import-popup.component';
import { GridColumnSettingPopupComponent } from './popup/grid-column-setting-popup/grid-column-setting-popup.component';
import { LoctFavComponent } from './popup/loct-fav/loct-fav.component';
import { LoctFavoritePopupComponent } from './popup/loct-favorite-popup/loct-favorite-popup.component';
import { LoctGrpComponent } from './popup/loct-popup/loct-grp/loct-grp.component';
import { LoctSearchRsltComponent } from './popup/loct-popup/loct-search-rslt/loct-search-rslt.component';
import { LoginPwResetPopupComponent } from './popup/login-pw-reset-popup/login-pw-reset-popup.component';
import { LoginPwUpdPopupComponent } from './popup/login-pw-upd-popup/login-pw-upd-popup.component';
import { LoginUserFavComponent } from './popup/login-user-fav/login-user-fav.component';
import { LoginUserFavService } from './popup/login-user-fav/login-user-fav.service';
import { MyInfoPopupComponent } from './popup/my-info-popup/my-info-popup.component';
import { PartnerFavComponent } from './popup/partner-fav/partner-fav.component';
import { PartnerFavService } from './popup/partner-fav/partner-fav.service';
import { PotoBatchRegPopupComponent } from './popup/poto-batch-reg-popup/poto-batch-reg-popup.component';
import { PotoPreviewPopupComponent } from './popup/poto-preview-popup/poto-preview-popup.component';
import { PwUpdPopupComponent } from './popup/pw-upd-popup/pw-upd-popup.component';
import { ReaderSelectPopupComponent } from './popup/reader-select-popup/reader-select-popup.component';
import { SearchConditionPopupComponent } from './popup/search-condition-popup/search-condition-popup.component';
import { UpdRsnPopupComponent } from './popup/upd-rsn-popup/upd-rsn-popup.component';
import { UserFavComponent } from './popup/user-fav/user-fav.component';
import { UserFavoritePopupComponent } from './popup/user-favorite-popup/user-favorite-popup.component';
import { UserFavoriteComponent } from './popup/user-popup/user-favorite/user-favorite.component';
import { UserGrpComponent } from './popup/user-popup/user-grp/user-grp.component';
import { UserPopupComponent } from './popup/user-popup/user-popup.component';
import { UserPopupService } from './popup/user-popup/user-popup.service';
import { UserSearchRsltComponent } from './popup/user-popup/user-search-rslt/user-search-rslt.component';
import { UserTreeComponent } from './popup/user-popup/user-tree/user-tree.component';
import { UserPotoSelectPopupComponent } from './popup/user-poto-select-popup/user-poto-select-popup.component';
import { WebcamPotoSelectPopupComponent } from './popup/webcam-poto-select-popup/webcam-poto-select-popup.component';
import { SharedCommonModule } from './shared-common.module';
import { SharedLibsModule } from './shared-libs.module';


/**
 * 공유 모듈
 * @description 기능 모듈에서 사용하는 공통기능을 모아놓은 공유 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedLibsModule,
    SharedCommonModule,
    TranslateModule
  ],
  declarations: [
    UserFavComponent,
    UserFavoriteComponent,
    UserGrpComponent,
    UserPopupComponent,
    UserSearchRsltComponent,
    UserTreeComponent,
    FileImportPopupComponent,
    DeptFavComponent,
    PartnerFavComponent,
    GridColumnSettingPopupComponent,
    NgxModalDraggableDirective,
    LoginUserFavComponent,
    DevicePopupComponent,
    DeviceTreeComponent,
    DeviceFavoriteComponent,
    LoctGrpDeviceComponent,
    DeviceSearchRsltComponent,
    MultiAppotComponent,
    MultiSelectComponent,
    LoctPopupComponent,
    LoctTreeComponent,
    LoctFavoriteComponent,
    LoctGrpComponent,
    LoctSearchRsltComponent,
    UserPotoSelectPopupComponent,
    ReaderSelectPopupComponent,
    PwUpdPopupComponent,
    DeviceDoorControlPopupComponent,
    DeptPopupComponent,
    DeptTreeComponent,
    DeptFavoriteComponent,
    DeptSearchRsltComponent,
    PageHeaderComponent,
    AccsLevelComponent,
    SearchConditionPopupComponent,
    AcHdayMgntComponent,
    AcHdayNewPopupComponent,
    MyInfoPopupComponent,
    UserFavoritePopupComponent,
    DeptFavoritePopupComponent,
    LoctFavoritePopupComponent,
    LoginPwResetPopupComponent,
    LoginPwUpdPopupComponent,
    PotoBatchRegPopupComponent,
    WebcamPotoSelectPopupComponent,
    PotoPreviewPopupComponent,
    FileDownloadSettingPopupComponent,
    TempcardLendNewComponent,
    TempcardReturnNewComponent,
    LoctFavComponent,
    UpdRsnPopupComponent,
    EvPopupComponent,
    EvTreeComponent,
    EvSearchRsltComponent,
    TypeaheadSelectComponent
  ],
  providers: [
    ApiService,
    UserPopupService,
    DeptFavService,
    PartnerFavService,
    ComService,
    LoginUserFavService,
    DatePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedLibsModule,
    SharedCommonModule,
    TranslateModule,
    UserFavComponent,
    UserPopupComponent,
    FileImportPopupComponent,
    DeptFavComponent,
    PartnerFavComponent,
    GridColumnSettingPopupComponent,
    NgxModalDraggableDirective,
    LoginUserFavComponent,
    DevicePopupComponent,
    MultiAppotComponent,
    MultiSelectComponent,
    LoctPopupComponent,
    DeviceDoorControlPopupComponent,
    DeptPopupComponent,
    PageHeaderComponent,
    AccsLevelComponent,
    SearchConditionPopupComponent,
    AcHdayMgntComponent,
    MyInfoPopupComponent,
    UserFavoritePopupComponent,
    DeptFavoritePopupComponent,
    LoctFavoritePopupComponent,
    WebcamPotoSelectPopupComponent,
    TempcardLendNewComponent,
    TempcardReturnNewComponent,
    LoctFavComponent,
    TypeaheadSelectComponent
  ]
})
export class SharedModule { }
