import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

/**
 * 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @description 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @author
 * @license IDP v1.0.0
 * @see https://www.ag-grid.com/javascript-grid-cell-rendering-components/#angular-cell-render-components
 *
 * Your Angular components need to implement AgRendererComponent.
 * The ag Framework expects to find the agInit method on the created component, and uses it to supply the cell params.
 *
 * All of the methods in the ICellRenderer interface described above are applicable
 * to the Angular Component with the following exceptions:
 *
 * - init() is not used. Instead implement the agInit method (on the AgRendererComponent interface).
 * - destroy() is not used. Instead implement the AngularOnDestroy interface (ngOnDestroy) for any cleanup you need to do.
 * - getGui() is not used. Instead do normal Angular magic in your Component via the Angular template.
 */
@Component({
  selector: 'app-ag-grid-button',
  template: `
    <button class="btn-default" type="button" (click)="invokeParentMethod($event)" *ngIf="showButton()" [disabled]="params?.isDisabled && params?.isDisabled()">
      <i [class]="params?.buttonIcon" *ngIf="params?.buttonIcon"></i><span *ngIf="params?.buttonText">{{ params?.buttonText }}</span>
    </button>
  `,
  styles: [
    `      
      button {
        min-width: 25px;
        height: 25px;
        font-size: 1.4rem;
        padding: 0 6px;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
      }
      i {
        font-size: 1.3rem;
        color: #202020;
      }
      span {
        font-size: 1.1rem;
        text-indent: 0;
      }
    `
  ]
})
export class GridButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public cellValue: any;
  public funcName: string;
  public func: Function;

  /**
   * 초기화
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    this.cellValue = params.value;
    this.funcName = params.funcName;
    this.func = params.func;
  }

  /**
   * 버튼 콜백함수 실행
   */
  public invokeParentMethod(event) {
    event.stopPropagation();

    if (this.func) {
      this.func(this.params);
    }
    else {
      this.params.context.componentParent[this.funcName](this.params);
    }
  }

  /**
   * 버튼 show/hide 조건 체크
   */
  public showButton(): boolean {
    return !this.params.buttonShowColumn ||
      (this.params.buttonShowColumnValue && this.params.data[this.params.buttonShowColumn] === this.params.buttonShowColumnValue);
  }

  /**
   *
   * @returns {boolean}
   */
  refresh(): boolean {
    return false;
  }
}
