import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComService {
  private path = '/v1/api/idp/org/com/mgnt';

  constructor(private apiService: ApiService) { }

  getComUnitList(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getComUnitList'), params);
  }

  getUserComUnitList(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getUserComUnitList'), params
    );
  }

  insert(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/insert'), params);
  }

  update(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/update'), params);
  }

  multiDelete(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/multiDelete'), params);
  }

  getList(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getList'), params);
  }

  excelExport(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/excelExport'), params, {
      responseType: 'blob'
    });
  }
}
