import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

const defaultConfig = <IndividualConfig>{
  timeOut: 3000,
  positionClass: 'toast-bottom-right'
};

/**
 * 알림(Notify, Toast) 서비스
 * @description
 * 알림(Notify, Toast) 메시지를 출력하는 서비스
 * @author
 * @license IDP v1.0.0
 */
@Injectable({ providedIn: 'root' })
export class NotifyService {
  constructor(
    private router: Router,
    private toastr: ToastrService
  ) {

  }

  /**
   * Notify 설정 반환
   * @returns {IndividualConfig}
   */
  getConfig(config: {}): IndividualConfig {
    return {
      ...defaultConfig,
      ...config
    };
  }

  /**
   * Success Notify 표시
   * @param {string} body
   * @param {string} title
   * @param {IndividualConfig} config
   */
  success(body: string, title: string = 'Success', config?: {}) {
    this.toastr.success(body, title, this.getConfig(config));
  }

  /**
   * Success Notify 표시
   * @param {string} body
   * @param {string} title
   * @param {IndividualConfig} config
   */
  info(body: string, title: string = 'Info', config?: {}) {
    this.toastr.info(body, title, this.getConfig(config));
  }

  /**
   * Error Notify 표시
   * @param {string} body
   * @param {string} title
   * @param {IndividualConfig} config
   */
  error(body: string, title: string = 'Error', config?: {}) {
    this.toastr.error(body, title, this.getConfig(config));
  }

  /**
   * Warning Notify 표시
   * @param {string} body
   * @param {string} title
   * @param {IndividualConfig} config
   */
  warning(body: string, title: string = 'Warning', config?: {}) {
    this.toastr.warning(body, title, this.getConfig(config));
  }

  clear() {
    // this.snotifyService.clear();
  }
}
