import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private path = '/v1/api/idp/sys/time/zone';

  constructor(
    private apiService: ApiService,
  ) { }

  getTimeZoneUnitList(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getTimeZoneUnitList'), params);
  }
}
