import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * 폼 헬퍼 서비스
 *
 * 어플리케이션 폼 처리시 공통으로 사용하는 기능을 제공
 * 실제 어플맄이션 구현시 응용요구사항에 부합하도록 구현하시기 바랍니다.
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class FormHelper {

  /**
   * 폼 유효성 결과를 표시한다.
   * @param {FormGroup} formGroup
   */
  validateForm(formGroup?: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
}
