import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../services/managers/data.service';
import { NotifyService } from '../services/managers/notify.service';

/**
 * HTTP API 서비스
 * @description Angular HTTP Client 를 래핑한 서비스
 * 어플리케이션의 HTTP API 처리시 공통된 에러 및 로깅 처리가 필요한 경우 사용한다.
 * @author
 * @license
 */
@Injectable()
export class ApiService {
  constructor(private http: HttpClient,
    private dataService: DataService,
    private notifyService: NotifyService
  ) { }

  /**
   * GET 메소드 호출
   * @param {string} path
   * @param {HttpParams} params
   * @returns {Observable<any>}
   */
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${path}`, { params });
  }

  /**
   * PUT 메소드 호출
   * @param {string} path
   * @param {Object} body
   * @returns {Observable<any>}
   */
  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${path}`, JSON.stringify(body));
  }

  /**
   * POST 메소드 호출
   * @param {string} path
   * @param {Object} body
   * @returns {Observable<any>}
   */
  post(path: string, body: Object = {}, options?: Object): Observable<any> {
    if (!(body instanceof FormData) && this.dataService.getCurrentMenu() && this.dataService.getCurrentMenu().menuId) {
      if (body instanceof Array) {
        body.forEach(x => {
          x.curMenuId = this.dataService.getCurrentMenu().menuId;
          x.menuProgId = this.dataService.getCurrentMenu().menuProgId;
        });
      } else {
        body = {
          ...body,
          curMenuId: this.dataService.getCurrentMenu().menuId,
          curMenuProgId: this.dataService.getCurrentMenu().menuProgId
        };
      }
    }

    return this.http
      .post(path, body, options);
  }

  /**
   * DELETE 메소드 호출
   * @param path
   * @returns {Observable<any>}
   */
  delete(path): Observable<any> {
    return this.http
      .delete(`${path}`);
  }
}
