import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

/**
 * 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @description 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @author
 * @license IDP v1.0.0
 * @see https://www.ag-grid.com/javascript-grid-cell-rendering-components/#angular-cell-render-components
 *
 * Your Angular components need to implement AgRendererComponent.
 * The ag Framework expects to find the agInit method on the created component, and uses it to supply the cell params.
 *
 * All of the methods in the ICellRenderer interface described above are applicable
 * to the Angular Component with the following exceptions:
 *
 * - init() is not used. Instead implement the agInit method (on the AgRendererComponent interface).
 * - destroy() is not used. Instead implement the AngularOnDestroy interface (ngOnDestroy) for any cleanup you need to do.
 * - getGui() is not used. Instead do normal Angular magic in your Component via the Angular template.
 */
@Component({
  selector: 'app-ag-grid-label-icon',
  template: `
    <ul>
      <li *ngFor="let label of lebelList">
        <!--div class="inline-middle">
          <div class="grid-label-icon"-->
            <span>{{ label.text }}</span>      
          <!--/div>
        </div-->
      </li>
    </ul>    
  `,
  styles: [`
    ul {
      display: flex;
    }
    ul li:not(:first-child) {
      margin-left: 5px;
    }
    ul span {
      padding : 3px 4px;
      background-color: #ffffff;
      border: 1px solid #9facac;
      border-radius: 2px;
    }
    .inline-middle {
      display: inline-block;
      vertical-align: middle;
    } 
    .grid-label-icon {
      height: 22px;
      line-height: 22px;
      min-width: 20px;
      
      vertical-align: middle; 
      background-color: white;
      padding-right: 3px;
      padding-left: 3px;
    }
  `]
})
export class GridLabelIconRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public lebelList: any[];

  /**
   * 초기화
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    this.lebelList = params.lebelList;
  }

  /**
   *
   * @returns {boolean}
   */
  refresh(): boolean {
    return false;
  }
}
