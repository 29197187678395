<div class="modal-default modal-mdlg" role="document">
  <div class="modal-content">
    <div class="modal-header" modalDraggable>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">
        <span></span>
      </button>
      <h1 class="modal-title">{{'title_pw_upd' | translate}}</h1>
    </div>
    <div class="modal-body">
      <form [formGroup]="inputForm" autocomplete="off">
        <table class="table-detail">
          <colgroup>
            <col style="width:35%">
            <col style="width:65%">
          </colgroup>
          <tbody>
            <tr *ngIf="showLoginId">
              <th class="required"><span>{{'lbl_login_id' | translate}}</span></th>
              <td>
                <input type="text" class="form-control" formControlName="loginId" (keydown)="changeLoginId()">
                <ng-container *ngIf="inputForm.controls.loginId.invalid && (inputForm.controls.loginId.dirty || inputForm.controls.loginId.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.loginId.errors.required || inputForm.controls.loginId.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_login_id' | translate} }}
                  </p>
                </ng-container>
              </td>
            </tr>
            <tr>
              <th class="required"><span>{{'lbl_current_pw' | translate}}</span></th>
              <td>
                <input type="password" class="form-control" formControlName="currentPw">
                <ng-container *ngIf="inputForm.controls.currentPw.invalid && (inputForm.controls.currentPw.dirty || inputForm.controls.currentPw.touched)">
                  <p class="invalid-text"
                    *ngIf="inputForm.controls.currentPw.errors.required || inputForm.controls.currentPw.errors.whitespace">
                    {{'msg_warn_00026' | translate: {param1: 'lbl_current_pw' | translate} }}
                  </p>
                </ng-container>
              </td>
            </tr>
            <tr>
              <th class="required"><span>{{'lbl_new_pw' | translate}}</span></th>
              <td>
                <input type="password" class="form-control" formControlName="newPw" autocomplete="new-password">
                <ng-container
                  *ngIf="inputForm.controls.newPw.invalid && (inputForm.controls.newPw.dirty || inputForm.controls.newPw.touched)">
                  <p class="invalid-text" *ngIf="inputForm.controls.newPw.errors.required || inputForm.controls.newPw.errors.whitespace">
                    {{ 'msg_warn_00026' | translate: {param1: 'lbl_new_pw' | translate} }}
                  </p>
                  <p class="invalid-text" *ngIf="inputForm.controls.newPw.errors.passwordCheck">
                    <span *ngIf="complexity === 1">{{'msg_info_00029' | translate}}</span>
                    <span *ngIf="complexity === 2">{{'msg_info_00030' | translate}}</span>
                    <span *ngIf="complexity === 3">{{'msg_info_00031' | translate}}</span>
                  </p>
                  <p class="invalid-text" *ngIf="inputForm.controls.newPw.errors.passwordUseWord">
                    <span>{{'msg_info_00050' | translate}}</span>
                  </p>
                </ng-container>
              </td>
            </tr>
            <tr>
              <th class="required"><span>{{'lbl_new_pw_conf' | translate}}</span></th>
              <td>
                <input type="password" class="form-control" [class.ng-invalid]="inputForm.errors?.passwordMatch"
                  formControlName="newPwConf">
                <ng-container *ngIf="(inputForm.controls.newPw.dirty || inputForm.controls.newPw.touched) &&
                (inputForm.controls.newPwConf.dirty || inputForm.controls.newPwConf.touched)">
                  <p class="invalid-text" *ngIf="inputForm.errors?.passwordMatch">
                    {{'msg_warn_00052' | translate}}
                  </p>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-block page-btn">
          <ul class="list-inline float-right pull-right">
            <li>
              <button class="btn btn-primary" (click)="save()"
                [disabled]="!inputForm.valid">{{'btn_save' | translate}}</button>
            </li>
            <li>
              <button type="button" class="btn btn-default"
                (click)="modalRef.hide()">{{'btn_close' | translate}}</button>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</div>