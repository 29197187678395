import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { passwordCheck } from './password-check.validator';

const PWD_CHECK_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PasswordCheckValidator),
  multi: true
};

@Directive({
  selector: '[passwordCheck][formControlName],[passwordCheck][formControl],[passwordCheck][ngModel]',
  providers: [PWD_CHECK_VALIDATOR]
})
export class PasswordCheckValidator implements Validator, OnInit, OnChanges {
  @Input() passwordCheck: number;

  private validator: ValidatorFn;
  private onChange: () => void;

  ngOnInit() {
    this.validator = passwordCheck(this.passwordCheck);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let key in changes) {
      if (key === 'passwordCheck') {
        this.validator = passwordCheck(changes[key].currentValue);
        if (this.onChange) this.onChange();
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
