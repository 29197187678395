<div class="modal-default modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header" modalDraggable>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">
        <span></span>
      </button>
      <h1 class="modal-title">{{'title_indvl_setup' | translate}}</h1>
    </div>
    <div class="modal-body">
      <form [formGroup]="inputForm">
        <table class="table-detail">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th class="required">{{'lbl_lngg' | translate}}</th>
              <td>
                <select class="form-control wd-full" formControlName="lnggCd">
                  <option *ngFor="let lngg of lnggList" [ngValue]="lngg.lnggCd">{{lngg.lnggNm}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="required">{{'lbl_time_zone' | translate}}</th>
              <td>
                <select class="form-control wd-full" formControlName="timeZoneId">
                  <option *ngFor="let timeZone of timeZoneList" [ngValue]="timeZone.timeZoneId">{{timeZone.timeZoneId}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="required">{{ 'lbl_sys_auth' | translate }}</th>
              <td>
                <select class="form-control wd-full" formControlName="sysAuthCd">
                  <option *ngFor="let sysAuth of sysAuthList" [ngValue]="sysAuth.sysAuthCd">{{ sysAuth.sysAuthNm }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-block page-btn">
          <ul class="list-inline float-right pull-right">
            <li>
              <button class="btn btn-primary" (click)="confirm()">{{'btn_conf' | translate}}</button>
            </li>
            <li>
              <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{'btn_close' | translate}}</button>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</div>