import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService, SessionService } from '@app/common/core/services';
import { AppConfService } from '@app/common/core/services/managers/app-conf.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomValidators } from '../../validators/custom-validators';
import { LoginPwService } from './login-pw.service';

@Component({
  selector: 'app-login-pw-upd-popup',
  templateUrl: './login-pw-upd-popup.component.html'
})
export class LoginPwUpdPopupComponent implements OnInit {
  @Input() showLoginId: boolean = false;
  @Input() lnggCd: string;

  inputForm = this.formBuilder.group(
    {
      currentPw: [null, Validators.required],
      newPw: [null, Validators.required],
      newPwConf: [null, Validators.required]
    },
    {
      validators: [CustomValidators.passwordMatch('newPw', 'newPwConf')]
    }
  );
  complexity: number = 0;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private appConfService: AppConfService,
    private loginPwService: LoginPwService,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    if (this.showLoginId) {
      this.inputForm.addControl('loginId', new FormControl(null, [Validators.required, CustomValidators.noWhitespace]));
    }

    this.appConfService.getList({ upperConf: 'password-rule' }).subscribe(res => {
      this.complexity = Number(res.appConfList?.find(appConf => appConf.conf === 'password-rule.complexity')?.confValue || 0);
      this.setPwValidator();
    });
  }

  setPwValidator() {
    const validators = [Validators.required];
    validators.push(CustomValidators.passwordCheck(this.complexity));
    if (this.showLoginId) {
      validators.push(CustomValidators.passwordUseWord([this.inputForm.controls.loginId.value]));
    } else {
      validators.push(CustomValidators.passwordUseWord([this.sessionService.getloginId()]));
    }
    this.inputForm.controls.newPw.setValidators(validators);
  }

  changeLoginId() {
    if (this.showLoginId) this.setPwValidator();
  }

  save() {
    const dto: any = {};
    if (this.showLoginId) dto.loginId = this.inputForm.controls.loginId.value;
    if (this.lnggCd) dto.lnggCd = this.lnggCd;
    dto.currentPw = this.inputForm.controls.currentPw.value;
    dto.newPw = this.inputForm.controls.newPw.value;

    this.loginPwService.update(dto).subscribe(res => {
      if (res.rsltCd === '00') {
        this.messageService.success(this.translateService.instant('msg_suss_00003')).subscribe(() => this.modalRef.hide());
      }
      else if (res.rsltCd === '06') {
        this.messageService.error(res.rsltMsg);
      }
      else {
        this.messageService.error(this.translateService.instant('msg_warn_00004', { param1: res.rsltMsg }));
      }
    });
  }
}