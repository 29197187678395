import { Injectable } from '@angular/core';
import { ApiService } from '../../http';

@Injectable()
export class QuickSearchService {
  path: string = '/v1/api/idp/quicksearch';

  constructor(private apiService: ApiService) {}

  getDeptUserList(params?: any) {
    return this.apiService.post(this.path.concat('/getDeptUserList'), params);
  }

  getUserList(params?: any) {
    return this.apiService.post(this.path.concat('/getUserList'), params);
  }

  getComnCdUnitList(params?: any) {
    return this.apiService.post(this.path.concat('/getComnCdUnitList'), params);
  }
}
