import { Component, HostListener } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-select-editor',
  template: `
    <div id="selectList" [style.width.px]="width">
      <div *ngFor="let item of items; let idx = index" [id]="'item' + idx" (click)="selectItem(item)" [title]="item[itemLabel]" [class.selected]="item === selectedItem">{{item[itemLabel]}}</div>
    </div>
  `,
  styles: [`
      #selectList {
        max-height: 160px;
        overflow-y: auto;
      }
      #selectList div {
        padding: 8px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      #selectList div:hover {
        background-color: #dddddd;
      }
      #selectList div.selected {
        background-color: #dddddd;
      }
  `]
})
export class GridSelectEditorComponent implements ICellEditorAngularComp {

  params: ICellEditorParams;
  width: number;
  items: any[];
  itemLabel: string;
  itemValue: string;
  selectedItem: any;

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.width = params.column.getActualWidth() - 1;

    const cellEditorParams = params.colDef.cellEditorParams instanceof Function ? params.colDef.cellEditorParams(params) : params.colDef.cellEditorParams;
    this.items = cellEditorParams.items;
    this.itemLabel = cellEditorParams.itemLabel;
    this.itemValue = cellEditorParams.itemValue;

    this.selectedItem = this.items.find(item => item[this.itemValue] === this.params.value) || this.items[0];
    setTimeout(() => document.getElementById('selectList').scrollTop = document.querySelector<HTMLElement>('#selectList div.selected')?.offsetTop);
  }

  getValue() {
    return this.selectedItem?.[this.itemValue] || null;
  }

  selectItem(item) {
    this.selectedItem = item;
    this.params.api.stopEditing();
  }

  isPopup() {
    return true;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const currentIndex = this.items.indexOf(this.selectedItem);
    if (event.key === 'ArrowUp' && currentIndex > 0) {
      this.selectedItem = this.items[currentIndex - 1];
      event.preventDefault();
    }
    else if (event.key === 'ArrowDown' && currentIndex < this.items.length - 1) {
      this.selectedItem = this.items[currentIndex + 1];
      event.preventDefault();
    }
    else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }
}