export const environment = {
  production: true,

  // 기본 서버 URL
  serverUrl: '.',
  loginUrl: '/v1/api/auth/login',
  logoutUrl: '/v1/api/auth/logout',

  // Mock API 서버 URL (assets 경로의 JSON 파일 경로)
  jsonDataUrl: 'assets/data',

  websocket: {
    serverUrl: '.',
    endpoint: '/ws',
    grid: {
      subscribe: '/topic/grid/reload',
    }
  }
};
