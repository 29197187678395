import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

/**
 * 타임피커 그리드 셀 에디터
 * @description
 * 그리드 내에서 사용하는 타임 피커
 * 타임 입출력은 moment 객체를 이용한다.
 * @author
 * @license IDP v1.0.0
 */
@Component({
  selector: 'timepicker-cell-editor',
  template: `    
      <div class="timepicker">
        <input
            type="text"
            class="form-control"
            [(ngModel)]="value"
            [dpDayPicker]="gridTimepickerConfig"
            [displayDate]="value"
            [mode]="'time'">  
      </div>
  `
})
export class GridTimepickerComponent implements ICellEditorAngularComp {

  /**
   * Input params
   */
  private params: any;

  /**
   * Output value
   */
  value: string;

  gridTimepickerConfig: IDatePickerConfig = {
    showSeconds: false,
    timeSeparator: ':',
    format: 'HH:mm',
    showTwentyFourHours: true,
    returnedValueType: ECalendarValue.Moment,
    appendTo: '.timepicker'
  };

  /**
   * 그리드로 부터 value, parameter 를 전달받음
   * @param {any} params cell value 와 cellEditor Params 에 정의된 value 를 받아옴
   */
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  /**
   * 그리드로 value return
   * @return {any}
   */
  getValue(): any {
    return this.value;
  }

  /**
   * 에디터를 팝업 표시 여부
   * @return {boolean}
   */
  isPopup(): boolean {
    return false;
  }
}
