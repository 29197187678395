import { Component, isDevMode, OnInit, Renderer2 } from '@angular/core';
import { NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private location: PlatformLocation
  ) { }

  ngOnInit(): void {
    if (isDevMode()) {
      console.log('%cFullLayoutComponent.ngOnInit()', 'color:#6ae9ff;font-weight:bold;font-size:16px;');
    }

    // 라우터 이벤트 처리
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (isDevMode()) {
          console.log('%cFullLayoutComponent.router.events: NavigationStart', 'color:#6ae9ff;font-weight:bold;font-size:16px;');
        }
      }

      if (event instanceof NavigationError) {
        console.error('NavigationError!!');

      }
    });

    // 뒤로가기 버튼 감지
    this.location.onPopState((event) => {
      if (isDevMode()) {
        console.log('%cFullLayoutComponent \'onPopState changed...\'', 'color:#6ae9ff;font-weight:bold;font-size:16px;', event);
      }
    });
  }
}
