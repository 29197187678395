import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { SharedModule } from '@common/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }
];

/**
 * 로그인 모듈
 * @description 로그인 화면 및 로그인 기능을 처리하는 모듈
 * 실제 어플리케이션 응용 요구사항에 부합하도록 재구현하시기 바랍니다.
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    TranslateModule
  ],
  exports: [],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule {}
