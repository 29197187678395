import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

/**
 * 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @description 그리드 셀 버튼을 위한 ag-grid 확장 컴포넌트
 * @author
 * @license IDP v1.0.0
 * @see https://www.ag-grid.com/javascript-grid-cell-rendering-components/#angular-cell-render-components
 *
 * Your Angular components need to implement AgRendererComponent.
 * The ag Framework expects to find the agInit method on the created component, and uses it to supply the cell params.
 *
 * All of the methods in the ICellRenderer interface described above are applicable
 * to the Angular Component with the following exceptions:
 *
 * - init() is not used. Instead implement the agInit method (on the AgRendererComponent interface).
 * - destroy() is not used. Instead implement the AngularOnDestroy interface (ngOnDestroy) for any cleanup you need to do.
 * - getGui() is not used. Instead do normal Angular magic in your Component via the Angular template.
 */
@Component({
  selector: 'app-ag-grid-text-icon',
  template: `
    <div>
      <span>{{text}}</span><i class="ml-5" [class]="iconClass" aria-hidden="true"></i>
    </div>
  `,
  styles: [`
    div {
      display: flex;
      align-items: center;
    }
  `]
})
export class GridTextIconRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public text: string;
  public iconClass: any;

  /**
   * 초기화
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    this.text = params.text;
    this.iconClass = params.iconClass;
  }

  /**
   *
   * @returns {boolean}
   */
  refresh(): boolean {
    return false;
  }
}
