import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@common/core/models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DataService } from '../managers/data.service';
import { MenuService } from '../managers/menu.service';
import { SessionService } from '../managers/session.service';

/**
 * 로그인 서비스
 *
 * 로그인 (서버) 기능을 처리하는 서비스
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class LoginService {

  private loginUrl = environment.loginUrl;
  private logoutUrl = environment.logoutUrl;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private dataService: DataService,
    private menuService: MenuService
  ) { }

  /**
   * 로그인
   * @param params
   * @returns {Observable<User>}
   */
  login(params: any) {
    this.dataService.clearCurrentMenu();
    const param = new HttpParams({ fromObject: params });
    return this.http.post<any>(this.loginUrl, param)
      .pipe(
        tap(res => {
          if (res.rsltCd === '00') {
            this.sessionService.storeSession(res.user);
            this.menuService.load();
          }
        })
      );
  }

  /**
   * 로그아웃
   */
  logout(): Observable<boolean> {
    this.dataService.clearCurrentMenu();

    return this.http.post(this.logoutUrl, {})
      .pipe(
        map(() => true),
        catchError(() => of(true)),
        tap(() => {
          this.sessionService.clearSession();
        })
      );
  }
}
