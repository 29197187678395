import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { password } from './password.validator';

const PWD_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PasswordValidator),
  multi: true
};

@Directive({
  selector: '[password][formControlName],[password][formControl],[password][ngModel]',
  providers: [PWD_VALIDATOR]
})
export class PasswordValidator implements Validator {
  validate(c: AbstractControl): {[key: string]: any} {
    return password(c);
  }
}
