import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './frameset/containers/full-layout';
import { NotFoundComponent, RedirectComponent } from './pages';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./contents/contents.module').then(m => m.ContentsModule)
      },
    ]
  },
  {
    path: 'redirect/:path',
    component: RedirectComponent
  },
  {
    path :  '**',
    component : NotFoundComponent
  }
];

/**
 * 루트 라우팅 모듈
 * @description
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
