import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-validation-tooltip',
  template: ` <div *ngIf="params.value?.length" class="validation-tooltip">
    <p *ngFor="let message of params.value">
      <span>{{ message }}</span>
    </p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        transition: opacity 0.2s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .validation-tooltip {
        pointer-events: none;
        opacity: 1;
        background-color: #ffffff;
        border: 1px solid #f92465;
      }

      .validation-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }
    `,
  ],
})
export class GridValidationTooltipComponent implements ITooltipAngularComp {
  params: ITooltipParams;

  agInit(params: ITooltipParams) {
    this.params = params;
  }
}
