import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from '@app/common/core/services';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @fileOverview 어플리케이션 사이드바의 네비게이션 컴포넌트
 * @author
 * @license IDP v1.0.0
 */

/**
 * 사이드바 네비게이션
 * @description
 */
@Component({
  selector: 'app-sidebar-nav',
  template: `    
    <div class="sidebar-button-group" *ngIf="navigation && navigation.hasSearchForm">
      <a class="button-nav highlight" href="#" (click)="$event.preventDefault()">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        <span>Sample Button</span>
      </a>
      <a href="#" (click)="$event.preventDefault()" class="button-nav highlight">
        <i class="fa fa-external-link"></i>
      </a>
    </div>
    <nav class="sidebar-nav" *ngIf="navigation && navigation['items']">
      <ul class="nav">
        <ng-template ngFor let-navitem [ngForOf]="navigation['items']">
          <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
          <li *ngIf="isTitle(navitem)">
            <app-sidebar-nav-title [title]='navitem'></app-sidebar-nav-title>
          </li>
          <li *ngIf="!isDivider(navitem) && !isTitle(navitem)">
            <app-sidebar-nav-item [item]="navitem" [depth]="1"></app-sidebar-nav-item>
          </li>
        </ng-template>
      </ul>
    </nav>`
})
export class AppSidebarNavComponent implements OnInit {

  @Input() public navigation: any;

  constructor() { }

  public isDivider(item) {
    return !!item.divider;
  }

  public isTitle(item) {
    return !!item.title;
  }

  ngOnInit() {

  }
}

/**
 * 사이드바 네비게이션 아이템
 * @description
 */
@Component({
  selector: 'app-sidebar-nav-item',
  template: `
    <li *ngIf="!isDropdown(); else dropdown" [ngClass]="hasClass() ? 'nav-item depth' + depth + ' ' + item.class : 'nav-item depth' + depth">
      <app-sidebar-nav-link [link]="item" [depth]="depth"></app-sidebar-nav-link>
    </li>
    <ng-template #dropdown>
      <li [ngClass]="hasClass() ? 'nav-item nav-dropdown depth' + depth + ' ' + item.class : 'nav-item nav-dropdown depth' + depth"
          [class.open]="isActive()"
          routerLinkActive="open"
          appNavDropdown>
        <app-sidebar-nav-dropdown [link]="item" [depth]="depth"></app-sidebar-nav-dropdown>
      </li>
    </ng-template>
  `
})
export class AppSidebarNavItemComponent implements OnInit {
  @Input() item: any;

  @Input() depth: number = 0;

  public hasClass() {
    return !!this.item.class;
  }

  public isDropdown() {
    return !!this.item.children && this.item.children.length > 0;
  }

  public thisUrl() {
    return this.item.url;
  }

  public isActive() {
    return !!this.thisUrl() && this.router.isActive(this.thisUrl(), false);
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
}

/**
 * 사이드바 네비게이션 아이템 링크 (실제 메뉴 링크)
 * @description
 */
@Component({
  selector: 'app-sidebar-nav-link',
  template: `
    <a *ngIf="!isExternalLink(); else external"
       [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
       [class.active]="link.isActive"
       [routerLink]="[link.url]"
       (click)="hideMobile()"
       title="{{ link.name }}">
      <i *ngIf="isIcon()" class="{{ link.icon ? link.icon : 'bullet' }}"></i>
      <span *ngIf="isRoot() && !isIcon()" class="icon-nav-circle">
        {{ link.name.substr(0, 1) }}
      </span>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge?.variant">{{ link.badge?.text }}</span>
    </a>
    <ng-template #external>
      <a [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'" 
         [ngStyle]="link.styles"
         href="{{ link.url }}"
         target="_blank"
         title="{{ link.name }}">
        <i *ngIf="isIcon()" class="{{ link.icon ? link.icon : 'bullet' }}"></i>
        <span *ngIf="isRoot() && !isIcon()" class="icon-nav-circle">
          {{ link.name.substr(0, 1) }}
        </span>
        {{ link.name }} 
        <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge?.variant">{{ link.badge?.text }}</span>
      </a>
    </ng-template>
  `
})
export class AppSidebarNavLinkComponent implements OnInit, OnDestroy {
  @Input() link: any;

  @Input() depth: number = 0;

  routerSubscription: Subscription;

  public hasVariant() {
    return !!this.link.variant;
  }

  public isBadge() {
    return !!this.link.badge;
  }

  public isExternalLink() {
    return this.link.url.substring(0, 4) === 'http';
  }

  public isRoot() {
    return this.depth === 1;
  }

  public isIcon() {
    // 루트레벨 메뉴는 아이콘이 없는 경우 불릿으로 대체하지 않는다.
    return this.link.icon || (!this.isRoot() && !this.link.icon);
  }

  public hideMobile() {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  }

  public isActive(linkId) {
    return this.dataService.getCurrentMenu() && this.dataService.getCurrentMenu().menuId === linkId;
  }

  constructor(
    private dataService: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.link.isActive = this.dataService.getCurrentMenu() && this.dataService.getCurrentMenu().menuId === this.link.id;

    this.routerSubscription = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        this.link.isActive = this.dataService.getCurrentMenu() && this.dataService.getCurrentMenu().menuId === this.link.id;
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}

/**
 * 사이드바 네비게이션 드롭다운
 * @description
 */
@Component({
  selector: 'app-sidebar-nav-dropdown',
  template: `
    <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle title="{{ link.name }}">
      <i *ngIf="isIcon()" class="{{ link.icon ? link.icon : 'bullet' }}"></i>
      <span *ngIf="isRoot() && !isIcon()" class="icon-nav-circle">
        {{ link.name.substr(0, 1) }}
      </span>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>
    <ul class="nav-dropdown-items">
      <ng-template ngFor let-child [ngForOf]="link.children">
        <app-sidebar-nav-item [item]="child" [depth]="depth + 1"></app-sidebar-nav-item>
      </ng-template>
    </ul>
  `
})
export class AppSidebarNavDropdownComponent {
  @Input() link: any;

  @Input() depth: number = 0;

  public isRoot() {
    return this.depth === 1;
  }

  public isBadge() {
    return !!this.link.badge;
  }

  public isIcon() {
    // 루트레벨 메뉴는 아이콘이 없는 경우 불릿으로 대체하지 않는다.
    return this.link.icon || (!this.isRoot() && !this.link.icon);
  }

  constructor() { }
}

/**
 * 사이드바 네비게이션 타이틀
 * @description
 */
@Component({
  selector: 'app-sidebar-nav-title',
  template: ''
})
export class AppSidebarNavTitleComponent implements OnInit {
  @Input() title: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const li = this.renderer.createElement('li');
    const name = this.renderer.createText(this.title.name);

    this.renderer.addClass(li, 'nav-title');

    if (this.title.class) {
      const classes = this.title.class;
      this.renderer.addClass(li, classes);
    }

    if (this.title.wrapper) {
      const wrapper = this.renderer.createElement(this.title.wrapper.element);
      this.renderer.appendChild(wrapper, name);
      this.renderer.appendChild(li, wrapper);
    } else {
      this.renderer.appendChild(li, name);
    }
    this.renderer.appendChild(nativeElement, li);
  }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent
];
