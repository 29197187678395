import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

/**
 * 이미지 프리로딩 디렉티브
 *
 * 이미지 프리로딩 및 에러시 기본 이미지로 대체하는 디렉티브
 *
 * @author
 * @license IDP v1.0.0
 */
@Directive({
  selector: 'img[default]'
})
export class ImgPreloadDirectiveDirective {
  @Input() default: string;

  @Input() @HostBinding('src') src: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('error')
  onError() {
    this.src = this.default;
  }

  @HostListener('load')
  onLoad() {
    this.renderer.addClass(this.elementRef.nativeElement, 'image-loaded');
  }
}
