import { Directive, HostListener } from '@angular/core';
import { APP_MENU_ROOT } from '@app/app.constants';
import { UiService } from '@app/contents/sys/ui/ui.service';

/**
* Allows the aside to be toggled via click.
*/
@Directive({
  selector: '[appAsideMenuToggler]',
})
export class AsideToggleDirective {
  constructor(
    private uiService: UiService
  ) { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    $event.currentTarget.classList.toggle('open');
    const notificationOpen = !document.querySelector('body').classList.toggle('aside-menu-hidden');
    this.uiService.updateInsert({ menuId: APP_MENU_ROOT, uiKey: 'notificationOpen', value: notificationOpen, withoutLoading: true }).subscribe();
  }
}
