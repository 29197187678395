<!-- 
<div class="fixed-banner d-sm-down-none">
  <em>로컬 개발시 백엔드 API 서버가 준비되지 않은 경우 <strong>개발 모드</strong>로 로그인하여 주시기 바랍니다.<br></em>
  (개발모드는 로컬 개발환경의 웹팩서버를 Mock API 서버로 이용하여 인증 및 데이터 서비스를 처리합니다.)
</div> -->
<div id="container-fluid" class="login-container">
  <div class="slang">
    <ng-select class="lang" [items]="lnggList" bindLabel="lnggNm" bindValue="lnggCd" [searchable]="false" [clearable]="false" appendTo=".login-container" [(ngModel)]="lnggCd" (ngModelChange)="lnggChange()">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">
        <div class="lang-select">
          <i class="flag {{item.lnggCd}}" aria-hidden="true"></i><span>{{item.lnggNm}}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="main-contents login-main-contents">
    <img src="assets/images/logo/main-logo.png" class="logo-main" alt="Main Logo">
    <form class="login-form" #loginForm="ngForm" (ngSubmit)="credentials.username && credentials.password && login()" novalidate>
      <fieldset class="login-input">
        <legend>login input</legend>
        <div class="form-group">
          <input type="text"
                  name="username"
                  class="form-control"
                  placeholder="USER NAME"
                  [(ngModel)]="credentials.username"
                  #username="ngModel">
        </div>
        <div class="form-group">
          <input type="password"
                  name="password"
                  class="form-control"
                  placeholder="PASSWORD"
                  [(ngModel)]="credentials.password"
                  #password="ngModel"
                  autocomplete="current-password">
        </div>
        <div class="message-wrap">
          <ng-container *ngIf="loginForm.submitted">
            <p *ngIf="!credentials.username || !credentials.password" class="message"><i aria-hidden="true"></i>{{'msg_warn_00121' | translate}}</p>
          </ng-container>
          <p class="message">{{ errorInfo }}</p>                
        </div>
        <button type="submit" class="btn btn-primary"><!--{{'samplebutton'|translate}}-->LOGIN</button>
      </fieldset>
      <div class="login-form-bottom">
        <fieldset class="login-option">
          <legend>login option</legend>
          <div class="float-left">
            <div class="input-checkbox input-style">
                <label>
                  <input type="checkbox" class="form-control" [(ngModel)]="rememberId" [ngModelOptions]="{standalone: true}">
                  <span>{{'lbl_remember_id' | translate}}</span>
                </label>
              </div>
          </div>
          <div class="float-right">
            <a (click)="popupLoginPwReset()">{{ 'btn_pw_init' | translate }}</a>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
  <div class="footer">
    <img src="assets/images/logo/lgcns.png" alt="LG CNS Logo">
    <div class="list-flex">
      <ul>
        <li>
          <a href="assets/html/{{ privacyPolicyFileName }}.html" target="_blank" class="bold">{{'btn_privacy_policy' | translate}}</a>
        </li>
        <li>
          <a href="assets/help/SecuXper_IDP+_User_Manual_{{ lnggCd }}.pdf" target="_blank">{{'btn_info' | translate}}</a>
        </li>
      </ul>
      <ul *ngIf="phone || email">
        <li *ngIf="phone">
          <span><i class="phone" aria-hidden="true"></i>{{ phone }}</span>
        </li>
        <li *ngIf="email">
          <span><i class="mail" aria-hidden="true"></i>{{ email }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
<p class="copyright">COPYRIGHT© LG CNS Corp. ALL RIGHTS RESERVED</p>