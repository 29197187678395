import { Component, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginPwResetPopupComponent } from '@app/common/shared/popup/login-pw-reset-popup/login-pw-reset-popup.component';
import { LoginPwUpdPopupComponent } from '@app/common/shared/popup/login-pw-upd-popup/login-pw-upd-popup.component';
import { LnggService } from '@app/contents/sys/lngg/lngg.service';
import { AppConfService, HelpdeskService, LoginService, MessageService, NotifyService, SessionService } from '@common/core/services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { tap } from 'rxjs/operators';

/**
 * 로그인 컴포넌트
 * @description 로그인 화면을 구성하는 컴포넌트
 * @author
 * @license IDP v1.0.0
 */
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isDevMode: boolean = isDevMode();

  @LocalStorage('lnggCd', 'ko') lnggCd: string;
  lnggList: any[] = [];
  rememberId = false;

  /**
   * 로그인 정보
   * @type {any}
   */
  credentials: {
    username?: string,
    password?: string
  } = {
      username: '',
      password: ''
    };

  /**
   * 에러 메시지 정보
   */
  errorInfo: any;

  phone: string;
  email: string;
  privacyPolicyFileName: string;
  smsEnabled: boolean;
  emailEnabled: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private notifyService: NotifyService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private lnggService: LnggService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private appConfService: AppConfService,
    private helpdeskService: HelpdeskService
  ) { }

  ngOnInit() {
    this.credentials.username = this.localStorageService.retrieve('loginId') || '';
    this.rememberId = !!this.localStorageService.retrieve('loginId');

    this.lnggService.getLnggUnitList().subscribe(res => {
      this.lnggList = res.lnggUnitList || [];
      this.lnggChange();
    });

    this.helpdeskService.load().subscribe(res => {
      this.phone = res.phone;
      this.email = res.email;
    });

    this.appConfService.getList({ upperConf: 'privacy-policy' }).subscribe(res => {
      this.privacyPolicyFileName = res.appConfList?.find(appConf => appConf.conf === 'privacy-policy.file-name')?.confValue || 'privacy-policy';
    });

    this.appConfService.getList({ upperConf: 'notification' }).subscribe(res => {
      this.smsEnabled = (res.appConfList?.find(appConf => appConf.conf === 'notification.sms.enabled')?.confValue === 'Y') || false;
      this.emailEnabled = (res.appConfList?.find(appConf => appConf.conf === 'notification.email.enabled')?.confValue === 'Y') || false;
    });
  }

  /**
   * 로그인
   */
  login() {
    this.loginService.login({ ...this.credentials, lnggCd: this.lnggCd }).pipe(
      tap(res => {
        if (res.rsltCd !== '00') throw res;
      }),
    ).subscribe(
      (user: any) => {
        this.errorInfo = null;

        if (this.rememberId) {
          this.localStorageService.store('loginId', this.credentials.username);
        }
        else {
          this.localStorageService.clear('loginId');
        }

        // 개발 모드인 경우 사용자 정보 출력
        if (isDevMode()) {
          console.log('%c>>> User Session:', 'color:#28f875;font-weight:bold;');

          if (console.table) {
            console.table(this.sessionService.getUser());
          }
        }
        this.notifyService.success(this.translateService.instant('msg_suss_00017'), this.translateService.instant('lbl_login_suss'));
        this.router.navigate(['/']);
      },
      (err) => {
        if (err.passwordExpiredYn === 'Y') {
          this.messageService.error(err.rsltMsg).subscribe(res => {
            if (res.isConfirmed) this.modalService.show(LoginPwUpdPopupComponent, {
              initialState: {
                showLoginId: true,
                lnggCd: this.lnggCd
              },
              ignoreBackdropClick: true
            });
          }
          );
        } else {
          this.errorInfo = err.rsltMsg;
          this.notifyService.error(this.translateService.instant('msg_warn_00039'), this.translateService.instant('lbl_login_fail'));
        }
      }
    );
  }

  lnggChange() {
    this.translateService.use(this.lnggCd);
  }

  popupLoginPwReset() {
    this.modalService.show(LoginPwResetPopupComponent, {
      initialState: {
        lnggCd: this.lnggCd,
        smsEnabled: this.smsEnabled,
        emailEnabled: this.emailEnabled
      },
      ignoreBackdropClick: true
    });
  }
}
