import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'ag-grid-header',
  template: `
    <div class="grid-header">
      <div id="datatable2_filter" class="grid-filter" *ngIf="enableFilter">
        <label>
          <i class="fa fa-search"></i>
          <input type="search" class="form-control input-sm" [(ngModel)]="quickFilterValue" (input)="onQuickFilterChanged()">
        </label>
      </div>
      <div class="grid-toolbar" *ngIf="enableToolbar">
        <div class="grid-info mr-10">Total: <em>{{ totalCount ? totalCount : rowCount | number }}</em></div>
        <div class="grid-length" *ngIf="pagination">
          <label>
            <select name="datatable1_length" class="form-control input-sm" [(ngModel)]="paginationPageSize" (ngModelChange)="onPageSizeChanged()">
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>/ page</span>
          </label>
        </div>
        <div class="grid-buttons btn-group" *ngIf="enableConfig">
          <a class="btn btn-default buttons-collection buttons-colvis">
            <span>
              <i class="fa fa-cog"></i>
            </span>
          </a>
        </div>
      </div>
      <ng-content></ng-content>
    </div>`
})
export class AgGridHeaderComponent implements OnInit {

  @Input() gridApi: GridApi;

  @Input() paginationPageSize: number = 10;

  @Input() enableToolbar: boolean = true;

  @Input() enableFilter: boolean = true;

  @Input() pagination: boolean = true;

  @Input() enableConfig: boolean = false;

  @Input() totalCount: number;

  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();

  quickFilterValue: string = '';

  constructor() { }

  ngOnInit(): void { }

  onPageSizeChanged(): void {
    this.gridApi.paginationSetPageSize(Number(this.paginationPageSize));
    setTimeout(() => this.gridApi.sizeColumnsToFit());
    this.pageSizeChanged.emit(this.paginationPageSize);
  }

  onQuickFilterChanged(): void {
    this.gridApi.setQuickFilter(this.quickFilterValue);
  }

  get rowCount(): number {
    return this.gridApi && this.gridApi.getDisplayedRowCount();
  }

  get pageSize(): number {
    return this.gridApi && this.gridApi.paginationGetPageSize();
  }
}
