import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'child-cell',
  template: `<span>{{ cellValue }}</span> <button class="btn btn-icon-single" type="button" (click)="invokeParentMethod()"><i class="fa fa-search"></i></button>`,
  styles: [`
    .btn.btn-icon-single {
      padding: 0 4px 0 !important;
    }
    
    .btn.btn-icon-single i {
      color: #999;
      font-size: 1.2rem;
    }
    
    .btn.btn-icon-single:hover i {
      color: #202020;
    }`
  ]
})
export class GridPopupRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public cellValue: string;
  public funcName: string;

  agInit(params: any): void {
    this.params = params;
    this.cellValue = params.value;
    this.funcName = params.funcName;
  }

  public invokeParentMethod() {
    this.params.context.componentParent[this.funcName](this.params.node.rowIndex, this.params.colDef.headerName);
  }

  refresh(): boolean {
    return false;
  }
}
