import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '../util/lang';

export const passwordCheck = (complexity: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!isPresent(complexity) || isPresent(Validators.required(control)) || String(control.value).length > 20) return null;

    let upperCaseCharacters = (/[A-Z]+/g).test(control.value) ? 1 : 0;
    let lowerCaseCharacters = (/[a-z]+/g).test(control.value) ? 1 : 0;
    let numberCharacters = (/[0-9]+/g).test(control.value) ? 1 : 0;
    let specialCharacters = (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(control.value) ? 1 : 0;
    let characters = upperCaseCharacters + lowerCaseCharacters + numberCharacters + specialCharacters;
    let ruleCheck = false;

    // 2 or more character types & 10 min-length
    if (complexity === 1) {
      if (characters >= 2 && String(control.value).length >= 10) {
        ruleCheck = true;
      }
    }
    // 3 or more character types & 8 min-length
    else if (complexity === 2) {
      if (characters >= 3 && String(control.value).length >= 8) {
        ruleCheck = true;
      }
    }
    // 2 or more character types & 10 min-length + 3 or more character types & 8 min-length
    else {
      if ((characters >= 2 && String(control.value).length >= 10) || (characters >= 3 && String(control.value).length >= 8)) {
        ruleCheck = true;
      }
    }

    return ruleCheck ? null : { 'passwordCheck': true };
  };
};