import { Directive, HostListener } from '@angular/core';

/**
 * @fileOverview 사이드바 토글과 관련된 디렉티브
 * @author
 * @license IDP v1.0.0
 */

/**
 * 사이드바 토글 디렉티브
 * @description 사이드바를 접고 펼치는 버튼을 위한 디렉티브
 */
@Directive({
  selector: '[appSidebarToggler]'
})
export class SidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-hidden');
  }
}

/**
 * 사이드바 최소화 디렉티브
 * @description 사이드바를 최소화 및 확장하는 버튼을 위한 디렉티브
 */
@Directive({
  selector: '[appSidebarMinimizer]'
})
export class SidebarMinimizeDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-hidden');
  }
}

/**
 * 브랜드 최소화 디렉티브
 * @description 상단 브랜드 버튼 최소화 및 확장하는 버튼을 위한 디렉티브
 */
@Directive({
  selector: '[appBrandMinimizer]'
})
export class BrandMinimizeDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('brand-minimized');
  }
}

/**
 * 모바일 사이드바 토글 디렉티브
 * @description 모바일 보기 상태인 경우 사이브를 접고 펼치는 버튼을 위한 디렉티브
 */
@Directive({
  selector: '[appMobileSidebarToggler]'
})
export class MobileSidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-mobile-show');
  }
}

/**
 * 모바일 사이드바 백드롭 디렉티브
 * @description 모바일 상태에서 메뉴가 펼쳐진 경우 표시되는 백드롭 영역을 위한 디렉티브
 */
@Directive({
  selector: '[appMobileSidebarBackdrop]'
})
export class MobileSidebarBackdropDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-mobile-show');
  }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [
  SidebarToggleDirective,
  SidebarMinimizeDirective,
  BrandMinimizeDirective,
  MobileSidebarToggleDirective,
  MobileSidebarBackdropDirective
];
