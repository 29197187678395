<div class="sidebar-header">
  <div class="sidebar-context-title dropdown" dropdown>
    <button class="navbar-toggler d-md-down-none" type="button" appSidebarMinimizer appBrandMinimizer>
      <i class="fa" aria-hidden="true"></i>
    </button>
    <span dropdownToggle>
      {{ navigation?.title }}
      <button class="btn-mobile-menu-show" type="button">
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </button>
    </span>
    <div class="dropdown-menu main-menu" *dropdownMenu>
      <a href="#" class="dropdown-item" *ngIf="path !== 'home'" appMobileSidebarToggler routerLink="/home">Home</a>
      <a href="#" class="dropdown-item" *ngIf="path !== 'guides'" appMobileSidebarToggler routerLink="/guides">Guide</a>
      <a href="#" class="dropdown-item" *ngIf="path !== 'components'" appMobileSidebarToggler routerLink="/components">Components</a>
      <a href="#" class="dropdown-item" *ngIf="path !== 'patterns'" appMobileSidebarToggler routerLink="/patterns">Patterns</a>
      <a href="#" class="dropdown-item" *ngIf="path !== 'samples'" appMobileSidebarToggler routerLink="/samples">Samples</a>
    </div>
  </div>
</div>
