import { NgModule } from '@angular/core';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';

const NGX_BOOTSTRAP_MODULES: any[] = [
  AccordionModule,
  AlertModule,
  CollapseModule,
  DatepickerModule,
  BsDatepickerModule,
  ModalModule,
  BsDropdownModule,
  TabsModule,
  TimepickerModule,
  TooltipModule,
  TypeaheadModule,
  PopoverModule
];

@NgModule({
  exports: [
    ...NGX_BOOTSTRAP_MODULES
  ]
})
export class NgxBootstrapModule { }
