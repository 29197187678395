import { Injectable } from '@angular/core';
import { ApiService } from '../../http';
import { DataService } from './data.service';
import { MenuService } from './menu.service';

@Injectable({ providedIn: 'root' })
export class QuicklinkService {

  path = '/v1/api/idp/hom/brd/sys';

  constructor(
    private dataService: DataService,
    private menuService: MenuService,
    private apiService: ApiService
  ) { }

  isQuicklink(menuId = this.dataService.getCurrentMenu()?.menuId) {
    return this.findMenu(menuId)?.isQuicklink;
  }

  toggleQuicklink(menuId = this.dataService.getCurrentMenu()?.menuId) {
    this.apiService.post(this.path.concat('/toggleQuicklink'), { menuId }).subscribe(res => {
      if (res.rsltCd === '00') {
        this.findMenu(menuId).isQuicklink = res.isQuicklink;
      }
    });
  }

  findMenu(menuId) {
    for (let topMenu of this.menuService.menuList) {
      if (topMenu.id === menuId) return topMenu;
      for (let leftMenu of topMenu.items) {
        if (leftMenu.id === menuId) return leftMenu;

        if (leftMenu.children && leftMenu.children.length > 0) {
          for (let child of leftMenu.children) {
            if (child.id === menuId) return child;
          }
        }
      }
    }
  }
}