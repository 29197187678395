import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';

@Injectable({ providedIn: 'root' })
export class LnggService {
  path = '/v1/api/idp/sys/lngg/mgnt';

  constructor(
    private apiService: ApiService
  ) { }

  getLnggUnitList() {
    return this.apiService.post(this.path.concat('/getLnggUnitList'), {});
  }
}