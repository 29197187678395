import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';
import { User } from '@app/common/core/models';

@Injectable({ providedIn: 'root' })
export class MyInfoService {

  constructor(
    private apiService: ApiService
  ) { }

  updateMyInfo(user: User) {
    return this.apiService.post('/v1/api/idp/sys/myinfo/updateMyInfo', user);
  }
}