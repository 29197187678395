import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';

@Injectable()
export class PartnerFavService {
  partnerPath = '/v1/api/idp/org/copcom/mgnt';

  constructor(private apiService: ApiService) {}

  getCopcomUnitList(param?: any) {
    return this.apiService.post(this.partnerPath.concat('/getCopcomUnitList'), param);
  }
}
