import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

/**
 * ------------------------------------------------
 * Grid Multi Select Editor
 *
 * Author : LIM M,S
 *
 * History
 * - 2018.02.05 initial draft (LIM, M.S)
 *
 * ------------------------------------------------
 */
@Component({
    selector: 'select-editor-cell',
    template: `
                <ul class="list-inline">
                    <li><p>Dose: </p></li>
                    <li>
                        <input type="text" class="input-text" [(ngModel)]="dose">
                    </li>
                    <li>
                        <div class="form-group input-checkbox">
                            <label>
                                <input type="checkbox"><span>text</span>
                            </label>
                        </div>
                    </li>
                </ul>
                <br/>
                <ul class="list-inline">
                    <li><p>Form: </p></li>
                    <li>
                        <select name="selectForm" [(ngModel)]="form" class="input-select">
                            <option *ngFor="let option of orderForm" [value]="option.id">{{option.text}}</option>
                        </select>
                    </li>
                </ul>
                <br/>
                <ul class="list-inline">
                    <li><p>Route: </p></li>
                    <li>
                        <select name="selectRoute" class="input-text" [(ngModel)]="route" class="input-select">
                            <option *ngFor="let option of orderForm2" [value]="option.id">{{option.text}}</option>
                        </select>
                    </li>
                </ul>
                <br/>
                <ul class="list-inline">
                    <li><p>Time: </p></li>
                    <li>
                        <select name="selectTime" class="input-text" [(ngModel)]="time" class="input-select">
                            <option *ngFor="let option of orderForm3" [value]="option.id">{{option.text}}</option>
                        </select>
                    </li>
                </ul>
    `
})
export class GridMultiSelectComponent implements ICellEditorAngularComp {
    private params: any;
    dose: any;
    form: any;
    route: any;
    time: any;
    orderForm: any[];
    orderForm2: any[];
    orderForm3: any[];
    width: string;
    selectedRoute: string;
    selectedForm: string;

    /**
     * grid로 부터 value, parameter를 받아옴
     * @param {any} params cell value와 cellEditorParams에 정의된 value를 받아옴
     */
    agInit(params: any): void {
        this.params = params;
        this.dose = params.value.DOSE;
        this.form = params.value.FORM.id;
        this.route = params.value.ROUTE.id;
        this.time = params.value.TIME.id;

        this.orderForm = params.orderForm;
        this.orderForm2 = params.orderForm2;
        this.orderForm3 = params.orderForm3;
        this.width = params.width;
    }

    /**
     * grid로 value return
     * @return {any}
     */
    getValue(): any {
        const retVal = {};
        retVal['DOSE'] = this.dose;
        retVal['FORM'] = this.orderForm.find(item => {
            return item.id === this.form;
        });
        retVal['ROUTE'] = this.orderForm2.find(item => {
            return item.id === this.route;
        });
        retVal['TIME'] = this.orderForm3.find(item => {
            return item.id === this.time;
        });
        return retVal;
    }

    /**
     * editor를 popup으로 표시할지 여부를 정함 true: floating으로 표시
     * @return {boolean}
     */
    isPopup(): boolean {
        return true; // grid 위에 floating으로 표시
    }
}
