import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { noWhitespace } from './no-whitespace.validator';

const NO_WHITESPACE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NoWhitespaceValidator),
  multi: true
};

@Directive({
  selector: '[noWhitespace][formControlName],[noWhitespace][formControl],[noWhitespace][ngModel]',
  providers: [NO_WHITESPACE_VALIDATOR]
})
export class NoWhitespaceValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    return noWhitespace(c);
  }
}
