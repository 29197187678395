import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { GridApi, IHeaderParams } from 'ag-grid-community';

@Component({
  template: `<label>
               <input type="checkbox" class="form-control" [(ngModel)]="checkedAll" (change)="checkTrigger($event)">
               <span></span>
             </label>`
  // template: `<span class="ag-header-select-all" role="presentation" ref="cbSelectAll">`
})
export class GridSelectAllComponent implements IHeaderAngularComp {
  public gridApi: GridApi;
  public checkedAll: boolean = false;

  agInit(params: IHeaderParams): void {
    this.gridApi = params.api;
    this.gridApi.addEventListener('paginationChanged', () => {
      this.checkedAll = false;
      this.gridApi.deselectAll();
    });
    this.gridApi.addEventListener('selectionChanged', () => {
      const firstRow = this.gridApi.paginationGetCurrentPage() * this.gridApi.paginationGetPageSize();
      const lastRow = Math.min(firstRow + this.gridApi.paginationGetPageSize(), this.gridApi.paginationGetRowCount());
      const rowCount = lastRow - firstRow;
      this.checkedAll = this.gridApi.getDisplayedRowCount() && rowCount === this.gridApi.getSelectedRows().length;
    });
  }

  public checkTrigger(e: any) {
    this.checkedAll = e.target.checked;
    if (this.checkedAll) {
      const firstRow = this.gridApi.paginationGetCurrentPage() * this.gridApi.paginationGetPageSize();
      const lastRow = Math.min(firstRow + this.gridApi.paginationGetPageSize(), this.gridApi.paginationGetRowCount());
      for (let i = firstRow; i < lastRow; i++) {
        this.gridApi.getDisplayedRowAtIndex(i).selectThisNode(true);
      }
    } else {
      this.gridApi.deselectAll();
    }
  }

  refresh() {
    return false;
  }
}
