import { Component, OnDestroy, OnInit } from '@angular/core';
import { APP_MENU_ROOT } from '@app/app.constants';
import { MessageService, SessionService } from '@app/common/core/services';
import { NotificationService } from '@app/common/core/services/managers/notification.service';
import { WebsocketService } from '@app/common/core/websocket';
import { UiService } from '@app/contents/sys/ui/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html'
})
export class AppAsideComponent implements OnInit, OnDestroy {

  subscriptions: { notice?: Subscription, alert?: Subscription, reload?: Subscription } = {};
  setupOpen = false;
  setup = { notice: false, alert: false };

  constructor(
    public notificationService: NotificationService,
    private websocketService: WebsocketService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.uiService.getList({ menuId: APP_MENU_ROOT, uiKey: ['notice', 'alert'], withoutLoading: true }).subscribe(res => {
      if (res.uiSetupList && res.uiSetupList.length > 0) {
        for (let uiSetup of res.uiSetupList) {
          this.setup[uiSetup.uiKey] = uiSetup.value === 'true';

          if (this.setup[uiSetup.uiKey]) {
            this.subscribe(uiSetup.uiKey, this.setup[uiSetup.uiKey]);
          }
        }
      }
      else {
        this.subscribe('notice', this.setup.notice = true);
        this.subscribe('alert', this.setup.alert = true);
      }
    });

    this.subscriptions.reload = this.websocketService.subscribe('/topic/reload').subscribe(message => {
      if (message) {
        if (confirm(this.translateService.instant('msg_conf_00031'))) {
          window.location.reload();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.notice?.unsubscribe();
    this.subscriptions.alert?.unsubscribe();
    this.subscriptions.reload?.unsubscribe();
  }

  clear() {
    this.messageService.question('알림을 모두 삭제하시겠습니까?').subscribe(result => {
      if (result.isConfirmed) {
        this.notificationService.clear();
      }
    });
  }

  toggle(type: string) {
    this.setup[type] = !this.setup[type];
    this.uiService.updateInsert({ menuId: APP_MENU_ROOT, uiKey: type, value: this.setup[type] }).subscribe(res => {
      if (res.rsltCd === '00') {
        this.subscribe(type, this.setup[type]);
      }
    });
  }

  subscribe(type: string, enable: boolean) {
    if (enable) {
      if (type === 'notice') {
        this.subscriptions.notice = this.websocketService.subscribe('/topic/notice/' + this.sessionService.getUser().subscriptionId).subscribe(message => {
          const notice = JSON.parse(message.body);
          this.notificationService.info(notice.title, notice.updDtm);
        });
      }
      else if (type === 'alert') {
        this.subscriptions.alert = this.websocketService.subscribe('/topic/alert/' + this.sessionService.getUser().subscriptionId).subscribe(message => {
          const alert = JSON.parse(message.body);
          this.notificationService.alert(alert.alertTitle, alert.recvDtm);
        });
      }
    }
    else {
      this.subscriptions[type].unsubscribe();
    }
  }
}