import { NgModule } from '@angular/core';
import { Base64Validator } from './base64';
import { CreditCardValidator } from './credit-card';
import { DateValidator } from './date';
import { DateISOValidator } from './date-ios';
import { DigitsValidator } from './digits';
import { EmailValidator } from './email';
import { EqualValidator } from './equal';
import { EqualToValidator } from './equal-to';
import { GreaterThanValidator } from './greater-than';
import { GreaterThanEqualValidator } from './greater-than-equal';
import { JSONValidator } from './json';
import { LessThanValidator } from './less-than';
import { LessThanEqualValidator } from './less-than-equal';
import { MaxValidator } from './max';
import { MaxDateValidator } from './max-date';
import { MinValidator } from './min';
import { MinDateValidator } from './min-date';
import { NoWhitespaceValidator } from './no-whitespace';
import { NotEqualValidator } from './not-equal';
import { NotEqualToValidator } from './not-equal-to';
import { NumberValidator } from './number';
import { PasswordValidator } from './password';
import { PasswordCheckValidator } from './password-check';
import { PasswordMatchValidator } from './password-match';
import { PasswordUseWordValidator } from './password-use-word';
import { PhoneValidator } from './phone';
import { RangeValidator } from './range';
import { RangeLengthValidator } from './range-length';
import { UrlValidator } from './url';
import { UUIDValidator } from './uuid';
const CUSTOM_VALIDATOR_DIRECTIVES = [
  Base64Validator,
  CreditCardValidator,
  DateValidator,
  DateISOValidator,
  DigitsValidator,
  EmailValidator,
  EqualValidator,
  EqualToValidator,
  GreaterThanValidator,
  GreaterThanEqualValidator,
  JSONValidator,
  LessThanValidator,
  LessThanEqualValidator,
  MaxValidator,
  MaxDateValidator,
  MinValidator,
  MinDateValidator,
  NotEqualValidator,
  NotEqualToValidator,
  NumberValidator,
  PhoneValidator,
  RangeValidator,
  RangeLengthValidator,
  UrlValidator,
  UUIDValidator,
  PasswordValidator,
  PasswordMatchValidator,
  NoWhitespaceValidator,
  PasswordCheckValidator,
  PasswordUseWordValidator
];

/**
 * 사용자 정의 유효성 검사 (Validator) 모듈
 * @description 사용자 정의 유효성 검사 (Validator) 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  declarations: [CUSTOM_VALIDATOR_DIRECTIVES],
  exports: [CUSTOM_VALIDATOR_DIRECTIVES]
})
export class CustomValidatorModule { }
