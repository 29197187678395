import { Injectable } from '@angular/core';
import { APP_MENU_ROOT } from '@app/app.constants';
import { ApiService } from '@common/core/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SessionService } from './session.service';

/**
 * 메뉴 서비스
 *
 * @author
 * @license IDP v1.0.0
 */
@Injectable()
export class MenuService {

  private path = '/v1/api/idp/sys/menu/nav';

  menuList: any[] = [];
  breadcrumb: any = {};

  onMenuChange = new ReplaySubject<any>(0);

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
    private translateService: TranslateService
  ) {
    setTimeout(() => {
      if (this.sessionService.isAuthenticated) this.load();
    }, 500);

    this.translateService.onLangChange.pipe(delay(500)).subscribe((event: LangChangeEvent) => {
      if (this.sessionService.isAuthenticated) this.load();
    });
  }

  load() {
    this.findAll({ upperId: APP_MENU_ROOT }).subscribe(menu => {
      this.menuList = menu || [];

      this.breadcrumb = {};
      this.menuList.forEach(topMenu => topMenu.items.forEach(leftMenu => {
        if (leftMenu.children.length > 0) {
          leftMenu.children.forEach(child => {
            this.breadcrumb[child.id] = [topMenu.title, leftMenu.name, child.name];
          });
        }
        else {
          this.breadcrumb[leftMenu.id] = [topMenu.title, leftMenu.name];
        }
      }));

      this.onMenuChange.next(1);
    });
  }

  /**
   * 메뉴 목록 조회
   * @param params
   * @returns {Observable<any[]>}
   */
  findAll(params?: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getFullNav'), params);
  }

  /**
   * 메뉴 그룹 ID로 메뉴 목록 조회
   * @param {string} groupId
   * @returns {any}
   */
  findByGroupId(groupId: string): any {
    return this.menuList.find(menu => menu.id === groupId);
  }

  findByMenuId(menuId: string) {
    return this.menuList.find(menuTop => menuTop.items.some(menuLeft => menuLeft.id === menuId || menuLeft.children.some(menuLeftSub => menuLeftSub.id === menuId)));
  }
}
