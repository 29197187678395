import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UiService {
  private path = '/v1/api/idp/sys/ui/setup';

  constructor(private apiService: ApiService) { }

  getList(params: any): Observable<any> {
    return this.apiService.post(this.path.concat('/getList'), params);
  }

  updateInsert(params: any): Observable<any> {
    return this.apiService.post(this.path.concat('/updateInsert'), params);
  }
}
