import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';


export const passwordMatch = (passwordControlName: string, passwordConfirmControlName: string): ValidatorFn => {
  if (!passwordControlName || !passwordConfirmControlName) {
    throw Error('Control names must not be null');
  }

  return (formGroup: FormGroup): ValidationErrors | null => {
    const password = formGroup.controls[passwordControlName].value;
    const passwordConfirm = formGroup.controls[passwordConfirmControlName].value;

    return password === passwordConfirm ? null : { passwordMatch: true };
  };
};
