import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxBootstrapModule } from './libs/ngx-bootstrap/ngx-bootstrap.module';

/**
 * 공통 라이브러리 모듈
 * @description 기능 모듈에서 사용하는 공통기능을 모아놓은 공유 모듈
 * @author
 * @license IDP v1.0.0
 */
@NgModule({
  imports: [],
  declarations: [],
  exports: [
    // 3rd-party libraries
    AgGridModule,
    ChartsModule,
    NgSelectModule,
    DpDatePickerModule,

    // ngx-bootstrap
    NgxBootstrapModule
  ]
})
export class SharedLibsModule { }
