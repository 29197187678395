import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as moment from 'moment';

/**
 * 데이트피커 그리드 셀 에디터
 * @description
 * 그리드 내에서 사용하는 데이트 피커
 * 데이터 입출력은 moment 객체를 이용한다.
 * @author
 * @license IDP v1.0.0
 */
@Component({
  selector: 'app-datepicker-cell-editor',
  template: `    
      <div class="datepicker" [style.width.px]="width">
        <i aria-hidden="true"></i>
        <input #datepicker type="text" class="form-control wd-full" autocomplete="off" maxlength="10"
               bsDatepicker container="div.ag-popup-editor"
               [(ngModel)]="date"
               [bsConfig]="{ showWeekNumbers: false, adaptivePosition: true }"
               (ngModelChange)="dateChange()">
      </div>
  `
})
export class GridDatepickerComponent implements ICellEditorAngularComp {
  @ViewChild('datepicker')
  datepicker: ElementRef;

  params: ICellEditorParams;
  date: Date;
  width: number;

  /**
   * 그리드로 부터 value, parameter 를 전달받음
   * @param {any} params cell value 와 cellEditor Params 에 정의된 value 를 받아옴
   */
  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.width = params.column.getActualWidth() - 1;
    if (moment.isMoment(params.value)) {
      this.date = params.value.toDate();
    }
    else if (moment(params.value).isValid()) {
      this.date = new Date(params.value);
    }
  }

  afterGuiAttached() {
    setTimeout(() => {
      this.datepicker.nativeElement.click();
      this.datepicker.nativeElement.focus();
    });
  }

  getValue(): any {
    return this.date && (moment(this.date)).isValid() ? moment(this.date) : null;
  }

  dateChange() {
    this.params.stopEditing();
  }

  isPopup(): boolean {
    return true;
  }
}
