import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APP_MENU_ROOT } from '@app/app.constants';
import { NotificationService } from '@app/common/core/services/managers/notification.service';
import { QuicklinkService } from '@app/common/core/services/managers/quicklink.service';
import { LoginPwUpdPopupComponent } from '@app/common/shared/popup/login-pw-upd-popup/login-pw-upd-popup.component';
import { MyInfoPopupComponent } from '@app/common/shared/popup/my-info-popup/my-info-popup.component';
import { UiService } from '@app/contents/sys/ui/ui.service';
import { User } from '@common/core/models';
import { DataService, LoginService, MenuService, MessageService, SessionService } from '@common/core/services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/**
 * 어플리케이션 헤더 컴포넌트
 * @example
 * <app-header></app-header>
 */
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  animations: [
    trigger('minimized', [
      state('true', style({
        width: '0',
        left: '0'
      })),
      state('false', style({
        width: '0',
        left: '0'
      })),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  /**
   * 인디케이터 객체 참조
   */
  @ViewChild('indicator', { static: true }) indicatorElement: ElementRef;

  /**
   * 알람 객체 참조
   */
  @ViewChild('alarm') alarmElement: ElementRef;

  /**
   * 마지막에 구독해지해야 할 Subscription
   */
  subscription = new Subscription();

  /**
   * 홈 페이지 여부
   */
  isHome: boolean;

  /**
   * 검색 활성화 여부
   */
  isSearchOpen: boolean;

  /**
   * 인디케이터 최소화 여부
   * @type {boolean}
   */
  isIndicatorMinimized: boolean = false;

  /**
   * 메시지 카운트
   * @type {number}
   */
  messageCount: number = 0;

  /**
   * 네비게이션 데이터
   * @description
   * @type {any}
   */
  navigationList: any[];

  /**
   * 전체 메뉴 목록
   */
  navSearchList: any[] = [];
  navSearch = '';

  constructor(
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public sessionService: SessionService,
    private loginService: LoginService,
    private messageService: MessageService,
    public menuService: MenuService,
    private dataService: DataService,
    public quicklinkService: QuicklinkService,
    public notificationService: NotificationService,
    private uiService: UiService,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.isSearchOpen = false;
    // 홈화면 여부
    this.isHome = this.router.url === '/' || this.router.url === '/home';
    this.subscription.add(this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd)
      )
      .subscribe((data: any) => {
        this.isHome = data && (data.url === '/' || data.urlAfterRedirects === '/home');
        this.navigationList?.forEach(navigation => {
          navigation.isActive = this.dataService.getCurrentMenu() && navigation.items.flatMap(leftMenu => leftMenu.children && leftMenu.children.length > 0 ? leftMenu.children : [leftMenu]).some(leftMenu => leftMenu.id === this.dataService.getCurrentMenu().menuId);
        });
      })
    );

    // 헤더 메뉴 인디케이터 이벤트 핸들러
    // 헤더 영역 외부로 마우스가 이동하는 경우 인디케이터를 숨김처리함
    this.renderer.listen('body', 'mouseover', (event: any) => {
      if (!event.srcElement.attributes || !event.srcElement.attributes.getNamedItem('appHeaderIndicator')) {
        this.renderer.addClass(this.indicatorElement.nativeElement, 'disappear');
        this.renderer.setStyle(this.indicatorElement.nativeElement, 'width', '0');
        this.isIndicatorMinimized = false;
      }
    });

    // 알림영역 표시 여부
    this.uiService.getList({ menuId: APP_MENU_ROOT, uiKey: ['notificationOpen'], withoutLoading: true }).subscribe(res => {
      if (res.rsltCd === '00' && res.uiSetupList.length > 0 && res.uiSetupList[0].uiKey === 'notificationOpen') {
        if (res.uiSetupList[0].value === 'true') {
          document.querySelector('body').classList.remove('aside-menu-hidden');
        }
        else {
          document.querySelector('body').classList.add('aside-menu-hidden');
        }
      }
    });

    this.subscription.add(this.menuService.onMenuChange.subscribe(() => {
      this.getMenuList();
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getMenuList() {
    this.navSearchList = [];
    this.navigationList = this.menuService.menuList
      .filter(menu => menu.items && menu.items.length > 0)
      .map(menu => {
        for (let item of menu.items) {
          if (item.children?.length === 0) {
            this.navSearchList.push({ menuNm: item.name, url: item.url });
          }
          else if (item.children?.length) {
            this.navSearchList.push(...item.children.map(child => ({ menuNm: child.name, url: child.url })));
          }
        }

        return {
          ...menu,
          url: menu.items[0].children?.length ? menu.items[0].children[0].url : menu.items[0].url !== '/' ? menu.items[0].url : menu.url,
          isActive: this.dataService.getCurrentMenu() && menu.items.concat(menu.items.reduce((prev, current) => prev.concat(current.children), [])).some(leftMenu => leftMenu.id === this.dataService.getCurrentMenu().menuId)
        };
      });
  }

  /**
   * 검색 입력창 focus 처리
   * @param event
   */
  toggleSearchInput(event): void {
    event.currentTarget.nextElementSibling.focus();
  }

  moveTo(match: TypeaheadMatch) {
    this.navSearch = '';
    this.router.navigateByUrl(match.item.url);
  }

  /**
   * 개인정보수정
   */
  popupMyInfo() {
    this.modalService.show(MyInfoPopupComponent);
  }

  /**
   * 비밀번호변경
   */
  popupLoginPwUpd() {
    this.modalService.show(LoginPwUpdPopupComponent);
  }

  /**
   * 로그아웃
   */
  logout(): void {
    this.messageService.question(this.translateService.instant('msg_conf_00033'))
      .pipe(
        switchMap(
          result => result.isConfirmed ? this.loginService.logout() : of(false)
        )
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.router.navigateByUrl('/login');
        }
      });
  }

  /**
   * Navbar 인디케이터 애니메이션
   * @param event
   */
  animateIndicator(event: any): void {
    this.isIndicatorMinimized = false;
    const left = event.srcElement.getBoundingClientRect().left - document.querySelector('.navbar-quick').clientWidth;
    this.renderer.removeClass(this.indicatorElement.nativeElement, 'disappear');
    this.renderer.setStyle(this.indicatorElement.nativeElement, 'width', event.srcElement.clientWidth + 'px');
    this.renderer.setStyle(this.indicatorElement.nativeElement, 'left', left + 'px');
  }

  get user(): User {
    return this.sessionService.getUser();
  }
}
