import { Injectable } from '@angular/core';
import { ApiService } from '@app/common/core/http';
import { of, Subject } from 'rxjs';

@Injectable()
export class UserPopupService {
  private subject = new Subject<any>();
  userGrpPath: string = '/v1/api/idp/pp/user/grp';
  userFavoritePath: string = '/v1/api/idp/pp/user/favorite';
  deptPath: string = '/v1/api/idp/org/dept/mgnt';

  constructor(private apiService: ApiService) { }

  getTreeList(params?: any) {
    return of({ rsltCd: '05', treeList: [] });
    // return this.apiService.post(this.userGrpPath.concat('/getTree'), params);
  }

  getUserList(params?: any) {
    return this.apiService.post(
      this.userGrpPath.concat('/getUserList'),
      params
    );
  }

  getList(params?: any) {
    return this.apiService.post(
      this.userFavoritePath.concat('/getList'),
      params
    );
  }

  getDeptTreeList(params?: any) {
    return this.apiService.post(this.deptPath.concat('/getTree'), params);
  }

  getDeptUnitList(params?: any) {
    return this.apiService.post(this.deptPath.concat('/getDeptUnitList'), params);
  }

  multiInsert(params?: any) {
    return this.apiService.post(
      this.userFavoritePath.concat('/multiInsert'),
      params
    );
  }

  multiDelete(params?: any) {
    return this.apiService.post(
      this.userFavoritePath.concat('/multiDelete'),
      params
    );
  }

  sendData(data) {
    this.subject.next(data);
  }

  getData() {
    return this.subject.asObservable();
  }
}
