import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from '../../http';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  notificationList: { type: string, title: string, recvDtm: moment.Moment }[] = [];

  constructor(
    private apiService: ApiService
  ) {
  }

  alert(title: string, recvDtm: moment.Moment = moment()) {
    this.notificationList.splice(0, 0, { type: 'alert', title, recvDtm });
    this.notificationList.splice(20);
  }

  info(title: string, recvDtm: moment.Moment = moment()) {
    this.notificationList.splice(0, 0, { type: 'info', title, recvDtm });
    this.notificationList.splice(20);
  }

  warn(title: string, recvDtm: moment.Moment = moment()) {
    this.notificationList.splice(0, 0, { type: 'warn', title, recvDtm });
    this.notificationList.splice(20);
  }

  clear() {
    this.notificationList.splice(0);
  }

  get count() {
    return this.notificationList.length;
  }

  subscribe(params) {
    return this.apiService.post('/v1/api/idp/core/alarm/subscribe', params);
  }

  unsubscribe(params) {
    return this.apiService.post('/v1/api/idp/core/alarm/unsubscribe', params);
  }
}